'use client';

import React, { useContext, useEffect, useRef } from 'react';
import { Tracker, type TrackerOptions } from './Tracker';
import {
  DomTrackingWrapper,
  TrackingContext,
  useDevErrorOnChange,
} from './TrackingProvider';
import { type GA3Event, type TrackingData } from './types';

export interface PageTrackingProviderProps
  extends TrackingData,
    Omit<TrackerOptions, 'disabled'> {
  /**
   * Use React Context instead of `data-` attributes to maintain tracking data.
   *
   * @default true
   */
  enableReactTracking?: boolean;

  /**
   * Events to send for GA3 will be depcreated later on.
   */
  ga3?: GA3Event;
}

export const PageTrackingProvider: React.FC<PageTrackingProviderProps> = ({
  children,
  logging,
  mode,
  ga3,
  forceLowerCase,
  enableReactTracking = true,
  ...props
}) => {
  const trackingContext = useContext(TrackingContext);

  const trackingData = {
    ...trackingContext.trackingData,
    ...props,
  };

  const ga3Data = {
    ...trackingContext.ga3Data,
    ...ga3,
  };

  const isReactTrackingEnabledForTree =
    enableReactTracking || trackingContext.isReactTrackingEnabledForTree;

  const didPushPageTracking = useRef(false);

  if (
    trackingContext.hasPageTrackingProvider &&
    process.env.NODE_ENV !== 'production'
  ) {
    throw new Error('There could not be nested PageTrackingProvider');
  }

  if (
    process.env.NODE_ENV !== 'production' &&
    !enableReactTracking &&
    trackingContext.isReactTrackingEnabledForTree
  ) {
    throw new Error(
      `Don't disable React Tracking after it has been enabled in an outer provider`
    );
  }
  useDevErrorOnChange(enableReactTracking, 'enableReactTracking');
  useDevErrorOnChange(mode, 'mode');

  const shouldLog =
    typeof logging === 'boolean' ? logging : trackingContext.logging;

  const currentMode = typeof mode === 'string' ? mode : trackingContext.mode;

  useEffect(() => {
    if (enableReactTracking && !didPushPageTracking.current) {
      window.dataLayer = window.dataLayer || [];
      const tracker = new Tracker(null, {
        mode: currentMode,
        ga3: ga3Data,
        logging: shouldLog,
        forceLowerCase,
      });
      tracker.pageView(trackingData);
      didPushPageTracking.current = true;
    }
  });

  const trackingChildren = isReactTrackingEnabledForTree ? (
    children
  ) : (
    <DomTrackingWrapper pageload mode={mode} ga3={ga3} context={props}>
      {children}
    </DomTrackingWrapper>
  );

  return (
    <TrackingContext.Provider
      value={{
        ...trackingContext,
        hasPageTrackingProvider: true,
        trackingData,
        isReactTrackingEnabledForTree,
        logging: shouldLog,
        forceLowerCase:
          typeof forceLowerCase === 'boolean'
            ? forceLowerCase
            : trackingContext.forceLowerCase,
        mode: currentMode,
        ga3Data,
      }}
    >
      {trackingChildren}
    </TrackingContext.Provider>
  );
};
