import { nanoid } from 'nanoid';

import { getCurrentTime } from '@collab/utils/date';

import { useToastDispatch } from './ToastProvider';
import { ToastMessage, ToastModel, ToastVariant } from './toast.types';

const SUCCESS_MESSAGE_TIMEOUT = 7000;

const useToasts = () => {
  const dispatch = useToastDispatch();

  const successToast = (message: ToastMessage) => {
    const toast = createToast('success', message);

    dispatch({ type: 'add', toast });

    setTimeout(() => {
      dispatch({ type: 'remove', messageId: toast.id });
    }, SUCCESS_MESSAGE_TIMEOUT);
  };

  const errorToast = (message: ToastMessage) => {
    const toast = createToast('error', message);

    dispatch({ type: 'add', toast });
  };

  return { errorToast, successToast };
};

const createToast = (
  variant: ToastVariant,
  { key, text }: ToastMessage,
): ToastModel => ({
  id: nanoid(),
  timestamp: getCurrentTime(),
  key: `${key}-${variant}`,
  text,
  variant,
  count: 1,
});

export default useToasts;
