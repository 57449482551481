type JwtHeader = {
  typ: string;
};

type JwtPayload = {
  exp: number;
};

export type DecodedJwtToken = {
  header: JwtHeader;
  payload: JwtPayload;
};

export const decodeJwt = (
  jwt: string,
  base64decoder: (str: string) => string,
): DecodedJwtToken => {
  const [header, payload] = jwt
    .split('.')
    .slice(0, 2)
    .map((el) => el.replace(/-/g, '+').replace(/_/g, '/'))
    .map((el) => JSON.parse(base64decoder(el)));

  return { header, payload };
};

export const isJwtExpired = (token: DecodedJwtToken, offset: number = 0) =>
  (token.payload.exp - offset) * 1000 < Date.now();
