import React, { useEffect } from 'react';

import {
  MenuItemModel,
  SectionSubItemModel,
  useMenuStateActions,
} from '@collab/organisms/NavMenu';

import { parseData } from 'libs/api';
import { nextServerAuthFetch } from 'libs/auth-fetch';

import usePartnerMenuItemsStatus from './impl/usePartnerMenuItemsStatus';

const partnerSectionId = 'apis';

const MenuPartnerPatch: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const partnerItemsStatus = usePartnerMenuItemsStatus();
  const { insertSectionItems, removeSectionItems, forceUpdateActiveItem } =
    useMenuStateActions();
  const [addedPersistentIds, setAddedPersistentIds] = React.useState<string[]>(
    [],
  );

  useEffect(() => {
    const addOrRemoveItems = async () => {
      if (partnerItemsStatus === 'visible') {
        await showPartnerItems();
        forceUpdateActiveItem();
      }

      if (partnerItemsStatus === 'hidden') {
        hidePartnerItems();
      }
    };

    addOrRemoveItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerItemsStatus]);

  const showPartnerItems = async () => {
    const partnerItems = await getPartnerMenuItems();

    setAddedPersistentIds(getPersistentIds(partnerItems));

    insertSectionItems({ persistentId: partnerSectionId, items: partnerItems });
  };

  const hidePartnerItems = () => {
    if (addedPersistentIds.length !== 0) {
      removeSectionItems({
        persistentId: partnerSectionId,
        persistentIds: addedPersistentIds,
      });

      setAddedPersistentIds([]);
    }
  };

  return children;
};

const getPartnerMenuItems = async (): Promise<SectionSubItemModel[]> => {
  const response = await nextServerAuthFetch('/api/partner-menu-items');

  const data = await parseData(response, 200);

  return data.filter(isSectionSubItemModel);
};

const getPersistentIds = (items: SectionSubItemModel[]): string[] =>
  items
    .map((item) => item.persistentId)
    .filter((item): item is string => item !== undefined);

const isSectionSubItemModel = (
  item: MenuItemModel,
): item is SectionSubItemModel =>
  item.variant === 'page' ||
  item.variant === 'category' ||
  item.variant === 'module';

export default MenuPartnerPatch;
