import dynamic from 'next/dynamic';
import React from 'react';

import { logException } from '@collab/utils/logger';

const Error500View = dynamic(
  () => import('@collab/organisms/ErrorView/Error500View'),
);

type State = {
  hasError: boolean;
  error: any;
};

class ErrorBoundary extends React.Component<{ children?: React.ReactNode }> {
  state: State;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any) {
    logException(error);
  }

  render() {
    if (this.state.hasError) {
      return <Error500View />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
