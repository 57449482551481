import { isNotUndefined } from '@collab/utils/listUtil';

import {
  MenuItemModel,
  ModuleSubItemModel,
  normalizeModels,
  normalizeModuleSubItems,
  normalizeSectionSubItems,
  SectionSubItemModel,
} from './models';
import {
  MenuItem,
  MenuStateItem,
  ModuleItem,
  PageItem,
  ParentItem,
  SectionItem,
  SectionSubItem,
} from './types';
import { isNotModule, isParentItem } from './utils/isItemType';
import { createParentIds, isParentToId } from './utils/menuIds';

export type ReducerState = {
  accountItem: PageItem;
  items: MenuStateItem[];
  itemsFlat: FlatItems;
  activeId: string | undefined;
  openIds: string[];
};

export type InsertSectionItems = {
  persistentId: string;
  items: SectionSubItemModel[];
};

export type RemoveSectionItems = {
  persistentId: string;
  persistentIds: string[];
};

export type ReplaceModuleItems = {
  persistentId: string;
  items: ModuleSubItemModel[];
};

export type FlatItems = Map<string, MenuStateItem>;

type ActionTypes =
  | { type: 'href-change'; href: string }
  | { type: 'item-change'; id: string }
  | { type: 'close-non-active' }
  | { type: 'insert-section-items'; data: InsertSectionItems }
  | { type: 'remove-section-items'; data: RemoveSectionItems }
  | { type: 'replace-module-items'; data: ReplaceModuleItems };

export const reducer = (
  state: ReducerState,
  action: ActionTypes,
): ReducerState => {
  switch (action.type) {
    case 'href-change':
      return reduceHrefChange(state, action.href);
    case 'item-change':
      return reduceItemChange(state, action.id);
    case 'close-non-active':
      return reduceCloseNonActive(state);
    case 'insert-section-items':
      return reduceInsertSectionItems(state, action.data);
    case 'remove-section-items':
      return reduceRemoveSectionItems(state, action.data);
    case 'replace-module-items':
      return reduceReplaceModuleItems(state, action.data);

    // no default
  }
};

const reduceHrefChange = (state: ReducerState, href: string): ReducerState => {
  const item = findItemByPath(state.itemsFlat, href);

  if (item) {
    const openMenuItems = toggleOpenItems(state, item);
    const openIds = closeOpenModules(state, item, openMenuItems);

    return {
      ...state,
      activeId: item.id,
      openIds,
    };
  }

  return { ...state, activeId: undefined, openIds: [] };
};

const reduceItemChange = (
  state: ReducerState,
  itemId: string,
): ReducerState => {
  const item = getItemById(state.itemsFlat, itemId);

  if (item.variant === 'page') {
    return {
      ...state,
      activeId: item.id,
      openIds: toggleOpenItems(state, item),
    };
  }

  if (item.variant === 'section') {
    return {
      ...state,
      openIds: toggleOpenItems(state, item),
    };
  }

  if (item.variant === 'module') {
    return {
      ...state,
      openIds: toggleOpenItems(state, item),
    };
  }

  return state;
};

const reduceCloseNonActive = (state: ReducerState): ReducerState => {
  const { activeId, openIds } = state;
  const newOpenIds = activeId
    ? openIds.filter((id) => isParentToId(id, activeId))
    : [];

  return { ...state, openIds: newOpenIds };
};

const toggleOpenItems = (state: ReducerState, item: MenuItem): string[] => {
  const itemOpen = state.openIds.includes(item.id);

  if (itemOpen && item.variant !== 'page') {
    // Close current item if not a page.
    return state.openIds.filter((id) => id !== item.id);
  }

  if (item.variant === 'page') {
    // Close other pages, open itself and all parents.
    const otherOpen = allNonPages(state);
    const parents = createParentIds(item.id);

    return [...new Set([item.id, ...parents, ...otherOpen])];
  }

  // Open current item.
  return [item.id, ...state.openIds];
};

/**
 * Only inserts items to first level sections, and only inserts them last in section.
 * If any item is already inserted, it assumes all items are inserted and returns state as is.
 */
const reduceInsertSectionItems = (
  state: ReducerState,
  data: InsertSectionItems,
): ReducerState => {
  const sectionIndex = state.items.findIndex(
    (item) =>
      item.persistentId === data.persistentId && item.variant === 'section',
  );
  const section = state.items[sectionIndex] as SectionItem;

  if (!section || itemsAlreadyInserted(data.items, section.items)) {
    return state;
  }

  const newSectionItems = [
    ...section.items,
    ...normalizeSectionSubItems(data.items, section.id, section.items.length),
  ];
  const newStateItems = [...state.items];

  newStateItems[sectionIndex] = {
    ...section,
    items: newSectionItems,
  };

  const newItemsFlat = flattenMenuStateItems([
    ...newStateItems,
    state.accountItem,
  ]);

  return {
    ...state,
    items: newStateItems,
    itemsFlat: newItemsFlat,
  };
};

const itemsAlreadyInserted = (
  itemsToInsert: SectionSubItemModel[],
  existingItems: SectionSubItem[],
) =>
  itemsToInsert.some((itemToInsert) =>
    existingItems.find(
      (existingItem) => itemToInsert.persistentId === existingItem.persistentId,
    ),
  );

/**
 * Only removes items from first level sections. Also: does not renormalize items,
 * so if used to remove items which are not last in section, those items will not be
 * correctly normalized afterwards.
 */
const reduceRemoveSectionItems = (
  state: ReducerState,
  data: RemoveSectionItems,
): ReducerState => {
  const sectionIndex = state.items.findIndex(
    (item) =>
      item.persistentId === data.persistentId && item.variant === 'section',
  );
  const section = state.items[sectionIndex] as SectionItem;

  if (!section) {
    return state;
  }

  const newSectionItems = section.items.filter(
    (item) =>
      !data.persistentIds.some(
        (persistentId) => persistentId === item.persistentId,
      ),
  );
  const newStateItems = [...state.items];

  newStateItems[sectionIndex] = {
    ...section,
    items: newSectionItems,
  };

  const newItemsFlat = flattenMenuStateItems([
    ...newStateItems,
    state.accountItem,
  ]);

  return {
    ...state,
    items: newStateItems,
    itemsFlat: newItemsFlat,
  };
};

const reduceReplaceModuleItems = (
  state: ReducerState,
  data: ReplaceModuleItems,
): ReducerState => {
  const newItems = replaceItems(state.items, data);
  if (newItems === false) {
    return state;
  }

  const newItemsFlat = flattenMenuStateItems([...newItems, state.accountItem]);

  return {
    ...state,
    items: newItems,
    itemsFlat: newItemsFlat,
  };
};

const replaceItems = <T extends MenuItem[]>(
  menuItems: T,
  data: ReplaceModuleItems,
): T | false => {
  for (let i = 0; i < menuItems.length; i++) {
    const oldItem = menuItems[i];

    if (oldItem.persistentId === data.persistentId) {
      if (oldItem.variant === 'module') {
        const newItems = [...menuItems] as T;
        newItems[i] = replaceModule(oldItem, data);

        return newItems;
      }

      // We can not replace non-module items.
      return false;
    }

    if (isParentItem(oldItem)) {
      const newChildren = replaceItems(oldItem.items, data);

      if (newChildren !== false) {
        const newItems = [...menuItems] as T;
        newItems[i] = replaceParent(oldItem, newChildren);

        return newItems;
      }
    }
  }

  return false;
};

const replaceModule = (moduleItem: ModuleItem, data: ReplaceModuleItems) => ({
  ...moduleItem,
  items: normalizeModuleSubItems(data.items, moduleItem.id),
});

const replaceParent = <T extends ParentItem>(
  parentItem: T,
  items: T['items'],
): T => ({ ...parentItem, items });

const closeOpenModules = (
  state: ReducerState,
  item: PageItem,
  openIds: string[],
): string[] => {
  const { itemsFlat } = state;

  if (item.id === 'account') {
    return openIds;
  }

  // Close all modules.
  const nonOpenModules = closeModules(itemsFlat, openIds);

  // If we closed a module in last step, we reopen it here again.
  const parents = createParentIds(item.id);

  return [...new Set([...parents, ...nonOpenModules])];
};

export const createInitialState = (
  model: MenuItemModel[],
  pathWithoutHash: string,
): ReducerState => {
  const normalizedItems = normalizeModels(model);

  const accountItem = normalizedItems.find(
    (item) => item.id === 'account',
  ) as PageItem;

  const items = normalizedItems.filter((item) => item.id !== 'account');

  const itemsFlat = flattenMenuStateItems(normalizedItems);

  return reduceHrefChange(
    {
      accountItem,
      activeId: undefined,
      openIds: [],
      items,
      itemsFlat,
    },
    pathWithoutHash,
  );
};

const flattenItem = (flatItems: FlatItems, item: MenuStateItem): FlatItems => {
  if (item.variant === 'section' || item.variant === 'module') {
    flattenItems(item.items, flatItems);
  }

  flatItems.set(item.id, item);

  return flatItems;
};

const flattenItems = (menu: MenuStateItem[], prevItems: FlatItems) =>
  menu.reduce(flattenItem, prevItems);

export const flattenMenuStateItems = (menu: MenuStateItem[]) =>
  flattenItems(menu, new Map());

const findItemById = (items: FlatItems, id: string): MenuItem | undefined =>
  items.get(id);

const getItemById = (items: FlatItems, id: string): MenuItem => {
  const item = findItemById(items, id);
  if (!item) {
    throw new MenuItemByIdNotFound(id);
  }

  return item;
};

const findItemByPath = (items: FlatItems, path: string): PageItem | undefined =>
  [...items.values()]
    .filter((item): item is PageItem => item.variant === 'page')
    .find((item) => item.href === normalizePath(path));

const allNonPages = (state: ReducerState): string[] => {
  const pageIds = [...state.itemsFlat.values()]
    .filter((item) => item.variant === 'page')
    .map((item) => item.id);

  const toRemove = new Set(pageIds);
  return state.openIds.filter((id) => !toRemove.has(id));
};

const normalizePath = (path: string) => path.split('?')[0];

const closeModules = (flatItems: FlatItems, ids: string[]): string[] =>
  ids
    .map((id) => flatItems.get(id))
    .filter(isNotUndefined)
    .filter(isNotModule)
    .map((item) => item.id);

class MenuItemByIdNotFound extends Error {
  constructor(itemId: string) {
    super(`Could not find menu item with id ${itemId}`);
  }
}
