export type LoginParams = {
  redirectUri: string;
  state: string;
  codeChallenge: string;
  prompt: string | undefined;
};

export type ExchangeType = {
  codeVerifier: string;
  redirectUri: string;
  code: string;
};

export const toSearchParams = (params: LoginParams) => {
  const record: Record<string, string> = {
    redirectUri: params.redirectUri,
    state: params.state,
    codeChallenge: params.codeChallenge,
  };

  if (params.prompt) {
    record.prompt = params.prompt;
  }

  return new URLSearchParams(record);
};
