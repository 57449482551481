import { createContext, ReactNode, useContext } from 'react';

type ErrorContextState = {
  errorHandler: (error: unknown) => boolean;
};

type ErrorProps = ErrorContextState & {
  children: ReactNode;
};

const ErrorContext = createContext<ErrorContextState>({
  errorHandler: () => false,
});

const ErrorProvider: React.FC<ErrorProps> = ({ errorHandler, children }) => (
  <ErrorContext.Provider
    value={{
      errorHandler,
    }}
  >
    {children}
  </ErrorContext.Provider>
);

export const useErrorContext = () => useContext(ErrorContext);

export default ErrorProvider;
