const lockBodyScroll = (shouldLock: boolean) => {
  if (shouldLock) {
    enableLockBody();
  } else {
    disableLockBody();
  }
};

const getBodyStyle = () => document.body.style;

const enableLockBody = () => {
  const style = getBodyStyle();
  const offsetY = `-${Math.round(window.scrollY)}px`;

  style.position = 'fixed';
  style.width = '100%';
  style.top = offsetY;
  /* 
  when opening a modal with 'aria-modal="true", the global stylesheet 
  alters the bodys overflow property, causing the menu to jump.
  */
  style.overflow = 'unset';
};

const disableLockBody = () => {
  const style = getBodyStyle();
  const offsetYInt = -parseInt(style.top || '0');

  style.position = '';
  style.width = '';
  style.top = '';
  style.overflow = '';

  window.scrollTo(0, offsetYInt);
};

export default lockBodyScroll;
