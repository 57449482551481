import dynamic from 'next/dynamic';
import router from 'next/router';
import { useCallback, useState } from 'react';

import { ErrorProvider } from '@collab/libs/errors';
import { UnauthorizedError } from '@collab/utils/api';
import { removeTrailingSlash } from '@collab/utils/getValidRedirectUrl';

import useForceLogout from 'libs/users/useForceLogout';
import protectedUrls from 'utils/protectedUrls';

const ErrorPopup = dynamic(() => import('./ErrorPopup'));

const ErrorConfig: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const forceLogout = useForceLogout();
  const [showPopup, setShowPopup] = useState(false);

  const close = () => {
    const redirectUrl = getRedirect(window.location.pathname);
    setShowPopup(false);

    if (redirectUrl) {
      router.replace(redirectUrl);
    }
  };

  const errorHandler = useCallback(
    (error: unknown): boolean => {
      if (error instanceof UnauthorizedError) {
        forceLogout();
        setShowPopup(true);
        return true;
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <ErrorProvider errorHandler={errorHandler}>
      {children}
      <ErrorPopup isOpen={showPopup} close={close} />
    </ErrorProvider>
  );
};

const getRedirect = (url: string) => (isProtected(url) ? '/' : undefined);

const isProtected = (path: string) =>
  protectedUrls.includes(removeTrailingSlash(path));

export default ErrorConfig;
