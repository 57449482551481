import Cookies from 'js-cookie';

import { AuthToken } from '@collab/libs/auth/authModels';
import { setCookie } from '@collab/utils/cookie';

export enum LoginFlowCookie {
  AccessToken = 'access_token',
  RefreshToken = 'refresh_token',
  Login = 'login',
}

type AuthTokenCookie = Partial<AuthToken>;

export type LoginCookie = {
  verifier: string;
  noice: string;
};

const expires5Seconds = 5 / 86400;
const expires5Minutes = 5 / 1440;
const expires1Hour = 1 / 24;

export const expires5MinutesMinus5Seconds = expires5Minutes - expires5Seconds;
export const expires7DaysMinus1hour = 7 - expires1Hour;

export const getAccessToken = (): string | undefined =>
  Cookies.get(LoginFlowCookie.AccessToken);

export const getRefreshToken = (): string | undefined =>
  Cookies.get(LoginFlowCookie.RefreshToken);

export const getTokenCookie = (): AuthTokenCookie => {
  return {
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  };
};

export const setTokenCookie = (authToken: AuthToken) => {
  setCookie(LoginFlowCookie.AccessToken, authToken.accessToken, {
    expires: expires5MinutesMinus5Seconds,
  });
  setCookie(LoginFlowCookie.RefreshToken, authToken.refreshToken, {
    expires: expires7DaysMinus1hour,
  });
};

export const removeTokenCookie = () => {
  Cookies.remove(LoginFlowCookie.AccessToken);
  Cookies.remove(LoginFlowCookie.RefreshToken);
};

export const setLoginCookie = (loginCookie: LoginCookie) => {
  setCookie(LoginFlowCookie.Login, loginCookie);
};

export const clearLoginSession = () => {
  Object.values(LoginFlowCookie).forEach((c) => Cookies.remove(c));
  removeLoginRedirectUrl();
};

export const isAuthenticated = () => {
  return !!getTokenCookie().refreshToken;
};

const cookieOrUndefined = <T>(cookie: T): T | undefined => {
  return !cookie || Object.keys(cookie).length === 0 ? undefined : cookie;
};

const getLoginCookie = (): LoginCookie | undefined => {
  const cookie = cookieOrUndefined(Cookies.get(LoginFlowCookie.Login));

  return cookie ? JSON.parse(cookie) : undefined;
};

export const popLoginCookie = (): LoginCookie | undefined => {
  const cookie = getLoginCookie();
  Cookies.remove(LoginFlowCookie.Login);
  return cookie;
};

const LOCAL_STORAGE_REDIRECT_URL_ID = 'loginRedirectUrl';

export const setLoginRedirectUrl = (redirectUrl: string) => {
  localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL_ID, redirectUrl);
};

export const getLoginRedirectUrl = () =>
  localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL_ID);

export const removeLoginRedirectUrl = () =>
  localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL_ID);
