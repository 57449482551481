import { Track, TrackingProvider } from '@volvo-cars/tracking';

import { Link } from '@collab/atoms';
import { ThemeToggle } from '@collab/molecules';
import { environment } from '@collab/utils/environment';
import {
  volvoCareersLink,
  volvoSupportPrivacyLink,
} from '@collab/utils/externalLinks';

import styles from './Footer.module.css';

const FOOTER_MARGIN_HEIGHT_UNTIL_M = 48; //padding top + padding bottom
const FOOTER_MARGIN_HEIGHT_FROM_M = 76; //padding top + padding bottom
const FOOTER_CONTENT_HEIGHT_UNTIL_M = 144;
const FOOTER_CONTENT_HEIGHT_FROM_M = 72;

export const footerHeight = {
  untilM: FOOTER_MARGIN_HEIGHT_UNTIL_M + FOOTER_CONTENT_HEIGHT_UNTIL_M,
  fromM: FOOTER_MARGIN_HEIGHT_FROM_M + FOOTER_CONTENT_HEIGHT_FROM_M,
};

export const defaultLinks = {
  privacy: { text: 'Privacy', href: volvoSupportPrivacyLink },
  careers: { text: 'Careers', href: volvoCareersLink },
  cookies: { text: 'Cookies', href: '/cookies', target: '_blank' },
};

type LinkItem = {
  text: string;
  href: string;
  target?: string;
};

type DefaultLinks = {
  cookies?: LinkItem;
  contact?: LinkItem;
  privacy?: LinkItem;
  careers?: LinkItem;
};

type Links = DefaultLinks & {
  [name: string]: LinkItem;
};

export type FooterProps = {
  links: Links;
};

const Footer: React.FC<FooterProps> = ({ links }) => (
  <TrackingProvider eventCategory="footer">
    <footer className={styles.wrapper}>
      <ul className="flex-row flex-wrap justify-center">
        {Object.entries(links).map(([key, link]) => (
          <li key={key}>
            <Track eventAction="link|click" eventLabel={link.href}>
              <Link
                href={link.href}
                target={link.target}
                className={`${styles.link} micro`}
              >
                {link.text}
              </Link>
            </Track>
          </li>
        ))}
      </ul>
      <div className="flex-col justify-center text-center mr-4 md:flex-row">
        <small className="micro text-secondary">
          Copyright © {new Date().getFullYear()} Volvo Car Corporation
        </small>
        <small className="micro text-secondary">
          (or its affiliates or licensors)
        </small>
      </div>
      {!environment.IS_PROD && (
        <div className="mt-16">
          <ThemeToggle />
        </div>
      )}
    </footer>
  </TrackingProvider>
);

export default Footer;
