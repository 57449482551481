import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMenuStateActions } from '../hooks';

type MobileMenuActionsProps = {
  setMobileMenuOpen: (isMobileMenuOpen: boolean) => void;
  setToCOpen: (isToCOpen: boolean) => void;
  closeOverlays: () => void;
};

export const MobileMenuActionsContext = createContext<MobileMenuActionsProps>(
  {} as MobileMenuActionsProps,
);
export const MobileMenuToCContext = createContext<boolean>(false);
export const MobileMenuContainerContext = createContext<boolean>(false);

const MobileMenuProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { closeNonActiveItems } = useMenuStateActions();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isToCOpen, setToCOpen] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      closeNonActiveItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileMenuOpen]);

  const closeOverlays = useCallback(() => {
    setMobileMenuOpen(false);
    setToCOpen(false);
  }, []);

  const mobileMenuStateActions = useMemo(
    () => ({
      setMobileMenuOpen,
      setToCOpen,
      closeOverlays,
    }),
    [closeOverlays],
  );

  return (
    <MobileMenuProviders
      mobileMenuStateActions={mobileMenuStateActions}
      isToCOpen={isToCOpen}
      isMobileMenuOpen={isMobileMenuOpen}
    >
      {children}
    </MobileMenuProviders>
  );
};

export const MobileMenuProviders: React.FC<{
  mobileMenuStateActions: MobileMenuActionsProps;
  isToCOpen: boolean;
  isMobileMenuOpen: boolean;
  children: React.ReactNode;
}> = ({ mobileMenuStateActions, isToCOpen, isMobileMenuOpen, children }) => (
  <MobileMenuActionsContext.Provider value={mobileMenuStateActions}>
    <MobileMenuToCContext.Provider value={isToCOpen}>
      <MobileMenuContainerContext.Provider value={isMobileMenuOpen}>
        {children}
      </MobileMenuContainerContext.Provider>
    </MobileMenuToCContext.Provider>
  </MobileMenuActionsContext.Provider>
);

export default MobileMenuProvider;
