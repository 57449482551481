import { createContext, useContext } from 'react';

import { ThemeVariant } from './customThemeTokens';

export type ThemeContextState = {
  theme: ThemeVariant;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextState>({} as ThemeContextState);

export const useTheme = () => useContext(ThemeContext);

export default ThemeContext.Provider;
