type Env = 'local' | 'dev' | 'stage' | 'prod';

const getValidEnvName = (): Env =>
  (process.env.NEXT_PUBLIC_ENV ?? 'local') as Env;

const NAME: Env = getValidEnvName();
const DESIGN_LOGIN = process.env.NEXT_PUBLIC_DESIGN_LOGIN === 'true';
const IS_PROD =
  NAME === 'prod' || process.env.NEXT_PUBLIC_SHOW_AS_PROD === 'true';
const IS_CLIENT = typeof window !== 'undefined';

const DEVELOPER_PORTAL_URL = 'https://developer.volvocars.com/';
const DESIGN_PORTAL_URL = 'https://design.volvocars.com/';

export const environment = {
  NAME,
  IS_PROD,
  DESIGN_LOGIN,
  IS_CLIENT,
  DEVELOPER_PORTAL_URL,
  DESIGN_PORTAL_URL,
};

export const backend = {
  BASE_URL: process.env.NEXT_PUBLIC_BACKEND_BASE_URL,
};

export const getRequiredEnvVariable = (variableName: string) => {
  const envVariable = process.env[variableName];
  if (!envVariable) {
    throw new MissingEnvVariable(variableName);
  }
  return envVariable;
};

export class MissingEnvVariable extends Error {
  constructor(envVarName: string) {
    super(`Missing required environment variable ${envVarName}`);
  }
}
