import { useSWRConfig } from 'swr';

import { clearLoginSession } from '@collab/utils/session';

import { SESSION_KEY as VOLVO_ID_ACCESS_TOKEN } from 'libs/volvoId/volvoIdAccessToken';

const useClearUserData = () => {
  const { cache } = useSWRConfig();

  const clearUserData = () => {
    clearSessionTokens();
    clearLoginSession();
    if (cache instanceof Map) {
      cache.clear();
    }
  };

  return clearUserData;
};

const clearSessionTokens = () => {
  sessionStorage.removeItem(VOLVO_ID_ACCESS_TOKEN);
};

export default useClearUserData;
