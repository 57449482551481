import {
  MenuItem,
  MenuStateItem,
  ModuleItem,
  PageItem,
  ParentItem,
} from '../types';

export const isParentItem = (item: MenuItem): item is ParentItem =>
  item.variant === 'page' ||
  item.variant === 'section' ||
  item.variant === 'module';

export const isPageItem = (item: MenuItem): item is PageItem =>
  item.variant === 'page';

export const isNotModule = (
  item: MenuStateItem,
): item is Exclude<MenuStateItem, ModuleItem> => item.variant !== 'module';
