import { useRouter } from 'next/router';
import { Context, createContext, useContext, useEffect, useRef } from 'react';

type PrevPath = string | null;

export const PrevPathContext: Context<PrevPath> = createContext<PrevPath>(null);

export const usePrevPathContext = () => useContext(PrevPathContext);

export const PrevPathProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { asPath } = useRouter();
  const prevPath = useRef<PrevPath>(null);

  useEffect(() => {
    prevPath.current = asPath;
  }, [asPath]);

  return (
    <PrevPathContext.Provider value={prevPath.current}>
      {children}
    </PrevPathContext.Provider>
  );
};
