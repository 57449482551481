import dynamic from 'next/dynamic';

import { usePopup } from './PopupProvider';

const PopupContainer = dynamic(() => import('./PopupContainer'));

const Popup = () => {
  const { content, open, closePopup } = usePopup();

  return (
    <PopupContainer
      open={open}
      onClose={closePopup}
      title={content?.title ?? ''}
    >
      {content?.components}
    </PopupContainer>
  );
};
export default Popup;
