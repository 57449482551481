import { Router } from 'next/router';
import { useEffect } from 'react';

import { gtmPageView, initOneTrustWrapper } from '@collab/utils/analytics';

const useAppInit = () => {
  useEffect(() => {
    initOneTrustWrapper();

    Router.events.on('routeChangeComplete', gtmPageView);

    return () => {
      Router.events.off('routeChangeComplete', gtmPageView);
    };
  }, []);
};

export default useAppInit;
