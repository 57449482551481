import { captureException } from '@sentry/nextjs';

import { Logger } from '@collab/utils/logger';

class SentryLogger implements Logger {
  exception(error: unknown) {
    captureException(error);
  }
}

export default SentryLogger;
