import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { isAuthenticated } from '@collab/utils/session';

import { UserStatus } from './userStatus';

export type UserContextState = {
  isLoggedIn: boolean;
  isUserStatusLoading: boolean;
  userStatus: UserStatus;
  setUserStatus: (status: UserStatus) => void;
  forcedLogout: () => void;
  timedForcedLogout: () => void;
};

export const UserContext = createContext({} as UserContextState);

export const useUserContext = () => useContext(UserContext);

export const UserProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [userStatus, setUserStatus] = useState<UserStatus>('logging-in');
  const prevUser = useRef<UserStatus>();
  const timer = useRef<NodeJS.Timeout>();

  const isUserStatusLoading =
    userStatus === 'logging-in' || userStatus === 'logging-out';

  useEffect(() => {
    if (!isUserStatusLoading) {
      prevUser.current = userStatus;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  useEffect(() => {
    setUserStatus(isAuthenticated() ? 'logged-in' : 'logged-out');

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const forcedLogout = () => {
    if (prevUser.current === 'logged-out') {
      setUserStatus('logged-out');
    }
  };

  const timedForcedLogout = () => {
    timer.current = setTimeout(forcedLogout, 2000);
  };

  const state: UserContextState = {
    isLoggedIn: userStatus === 'logged-in',
    isUserStatusLoading,
    userStatus,
    setUserStatus,
    forcedLogout,
    timedForcedLogout,
  };

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};
