import { MaintenanceProvider } from '@collab/features/maintenance';
import { useErrorListener } from '@collab/libs/errors';

import useMaintenance from './impl/useMaintenance';

const MaintenanceConfig: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { message, isLoading, error } = useMaintenance();

  useErrorListener(error);

  const hasMaintenance = !isLoading && (!!error || !!message);

  return (
    <MaintenanceProvider hasMaintenance={hasMaintenance} message={message}>
      {children}
    </MaintenanceProvider>
  );
};

export default MaintenanceConfig;
