import { useTheme } from '@collab/style/ThemeProvider';

const DARK_THEME_LABEL = 'Dark theme';

const ThemeToggle = () => {
  const { toggleTheme, theme } = useTheme();

  return (
    <div className="flex gap-x-8 items-center justify-center">
      <button
        type="button"
        onClick={toggleTheme}
        role="switch"
        aria-checked={theme === 'dark'}
        aria-label={DARK_THEME_LABEL}
      />
      <small className="micro text-secondary">{DARK_THEME_LABEL}</small>
    </div>
  );
};

export default ThemeToggle;
