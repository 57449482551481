const zIndex = {
  mobileTocBackdrop: 100,
  mobileToc: 110,
  header: 200,
  infoBanner: 210,
  mobileMenuBackdrop: 300,
  mobileMenu: 310,
  popupBackdrop: 400,
  popup: 410,
  toastQueue: 500,
};

export default zIndex;
