import { Context, createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export type FeatureFlagsContextState = {};

export const FeatureFlagsContext: Context<FeatureFlagsContextState> =
  createContext({} as any);

export const FeatureFlagsProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  const state = {};

  return (
    <FeatureFlagsContext.Provider value={state}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
