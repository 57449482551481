import React, { createElement } from 'react';

export type ComponentSpec<
  C extends React.ComponentType<React.PropsWithChildren<unknown>> = any,
> = [C, React.ComponentPropsWithRef<C>] | [C];

type Props = {
  components: ComponentSpec[];
};

const ComposeComponents: React.FC<React.PropsWithChildren<Props>> = ({
  components,
  children,
}) =>
  [...components]
    .reverse()
    .reduce(
      (acc, [Component, props]) => createElement(Component, props, acc),
      children || null,
    ) as React.ReactElement;

export default ComposeComponents;
