import { useEffect } from 'react';

import { useUserContext } from '@collab/features/user';
import { OAuthPrompt } from '@collab/libs/auth/oauth2';
import { useToastErrorHandler } from '@collab/libs/errors';

import { redirectLogin } from 'libs/auth/redirectLogin';

const useLogin = () => {
  const { setUserStatus, forcedLogout, timedForcedLogout } = useUserContext();
  const handleWithToast = useToastErrorHandler();

  useEffect(() => {
    return () => {
      window.removeEventListener('popstate', forcedLogout);
    };
  }, [forcedLogout]);

  const login = async (
    redirectUrl: string = window.location.pathname + window.location.hash,
    prompt?: OAuthPrompt,
  ) => {
    setUserStatus('logging-in');
    try {
      window.addEventListener('popstate', forcedLogout);

      await redirectLogin(redirectUrl, prompt);

      timedForcedLogout();
    } catch (e) {
      setUserStatus('logged-out');
      handleWithToast(e, redirectErrorMsg);
    }
  };

  return login;
};

const redirectErrorMsg = {
  text: 'We could not sign you in right now. Please try again later.',
  key: 'login-redirect',
};

export default useLogin;
