import { getTheme } from 'vcc-ui';

const volvo = getTheme({ variant: 'light' });

export const colors = {
  scrollbar: {
    darkGrey: {
      trackColor: volvo.color.ornament.divider,
      thumbColor: volvo.color.foreground.secondary,
    },
    grey: {
      trackColor: volvo.color.background.secondary,
      thumbColor: volvo.color.ornament.divider,
    },
  },
};
