import { TrackingProvider as VCCTrackingProvider } from '@volvo-cars/tracking';

import usePagePath from '@collab/hooks/usePagePath';

export const TrackingProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const pagePath = usePagePath();

  return (
    <VCCTrackingProvider eventCategory={pagePath} pageName={pagePath}>
      {children}
    </VCCTrackingProvider>
  );
};
