'use client';

import React, { createContext, useContext, useReducer } from 'react';
import { useLayoutEffect } from './useLayoutEffect';

// Using default breakpoints as defined by vcc-ui. In the process of removing
// dependencies of vcc-ui as it's moving towards deprecation, these breakpoint
// definitions have been moved inline to this file. Eventually, they should
// probably align with the breakpoints definition from @volvo-cars/css.
const themeBreakpoints = {
  onlyS: '@media (max-width: 479px)',
  untilM: '@media (max-width: 479px)',
  fromM: '@media (min-width: 480px)',
  onlyM: '@media (min-width: 480px) and (max-width: 1023px)',
  untilL: '@media (max-width: 1023px)',
  fromL: '@media (min-width: 1024px)',
  onlyL: '@media (min-width: 1024px) and (max-width: 1599px)',
  untilXL: '@media (max-width: 1599px)',
  fromXL: '@media (min-width: 1600px)',
  onlyXL: '@media (min-width: 1600px)',
};

export type ThemeBreakpointName = keyof typeof themeBreakpoints;
export type BreakpointsMatchMap = {
  [key in ThemeBreakpointName]: boolean;
};

/**
 * Get the initial breakpoint match values.
 *
 * These values will be used both in server side rendering and on the first client
 * side render, before the first matchMedia run. This means we do not get rehydration
 * warnings because the first client side rendering matches the server side rendering.
 */
function getInitialState(): BreakpointsMatchMap {
  const matches = Object.fromEntries(
    Object.entries(themeBreakpoints).map(([name]) => [name, false]),
  ) as BreakpointsMatchMap;
  // Default to assuming mobile viewport because the time between a default render
  // and the final state will take longer on slower mobile devices then on desktop.
  matches.onlyS = matches.untilM = matches.untilL = matches.untilXL = true;
  return matches;
}

const BreakpointsContext = createContext<BreakpointsMatchMap>(
  {} as BreakpointsMatchMap,
);

function breakpointsReducer(
  state: BreakpointsMatchMap,
  action: { name: ThemeBreakpointName; matches: boolean },
) {
  if (state[action.name] === action.matches) {
    return state;
  }
  return {
    ...state,
    [action.name]: action.matches,
  };
}

/**
 * Provider for tests to always return the select breakpoint matches.
 */
export const StaticBreakpointsProvider: React.FC<
  React.PropsWithChildren<{
    breakpoints?: Partial<BreakpointsMatchMap>;
  }>
> = ({ children, breakpoints }) => {
  const matches = breakpoints
    ? (Object.fromEntries(
        Object.entries(themeBreakpoints).map(([name]) => [
          name,
          breakpoints[name as keyof BreakpointsMatchMap] || false,
        ]),
      ) as BreakpointsMatchMap)
    : getInitialState();
  return (
    <BreakpointsContext.Provider value={matches}>
      {children}
    </BreakpointsContext.Provider>
  );
};

export const BreakpointsProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [breakpoints, dispatch] = useReducer(
    breakpointsReducer,
    getInitialState(),
  );

  useLayoutEffect(() => {
    if (!window.matchMedia) return;

    // Invoke matchMedia and define change listeners for each breakpoint
    const mqHandlers = Object.entries(themeBreakpoints).map(
      ([name, query]) => ({
        name: name as ThemeBreakpointName,
        mql: window.matchMedia(query.replace(/^@media /, '')),
        handler(event: MediaQueryListEvent) {
          dispatch({
            name: name as ThemeBreakpointName,
            matches: event.matches,
          });
        },
      }),
    );

    mqHandlers.forEach(({ name, mql, handler }) => {
      // Dispatch the initial matches for each media query to update the state
      dispatch({ name, matches: mql.matches });
      mql.addEventListener('change', handler);
    });
    return () => {
      mqHandlers.forEach(({ mql, handler }) =>
        mql.removeEventListener('change', handler),
      );
    };
  }, []);

  return (
    <BreakpointsContext.Provider value={breakpoints}>
      {children}
    </BreakpointsContext.Provider>
  );
};

/**
 * Defaults to small (<480) viewport on server side rendering.
 */
export const useBreakpoints = () => useContext(BreakpointsContext);
