import { useContext } from 'react';

import {
  ActiveItemContext,
  HeadingRefsContext,
  MenuContentContext,
  MenuStateActionsContext,
  MenuStateContext,
  OpenItemsContext,
} from './MenuState';
import { PageItem } from './types';
import { getParentId, isActive } from './utils/menuIds';

export const useMenuState = () => useContext(MenuStateContext);
export const useActiveItemId = () => useContext(ActiveItemContext);
export const useOpenMenuItems = () => useContext(OpenItemsContext);
export const useContentState = () => useContext(MenuContentContext);
export const useMenuStateActions = () => useContext(MenuStateActionsContext);

export const useActiveItem = () => {
  const activeId = useActiveItemId();
  const { itemsFlat } = useMenuState();

  return activeId ? (itemsFlat.get(activeId) as PageItem) : undefined;
};

export const useItemState = (itemId: string) => {
  const openIds = useOpenMenuItems();
  const activeId = useActiveItemId();

  const open = openIds.includes(itemId);
  const active = !!activeId && isActive(activeId, itemId);

  return { open, active };
};

export const useItemGuard = (itemIsProtected: boolean) => {
  const contentGuard = useMenuState().contentGuard;

  const shouldProtect =
    (contentGuard && !contentGuard.isVerified && itemIsProtected) ?? false;

  const protect = (href: string) => {
    if (!contentGuard) {
      throw new NoContentGuardProvided();
    }

    if (shouldProtect) {
      contentGuard.guard(href);
    }
  };

  return { protect, shouldProtect };
};

export const useAccount = () => useMenuState().account;

export const useMenuItems = () => useMenuState().items;

export const useAccountItem = () => useMenuState().accountItem;

export const useHeadingRefs = () => useContext(HeadingRefsContext);

export const useIsRootItem = (itemId: string) => {
  const { itemsFlat } = useMenuState();
  const parentId = getParentId(itemId);

  return !parentId || itemsFlat.get(parentId)?.variant === 'module';
};

class NoContentGuardProvided extends Error {}
