/**
 * Menu ID strucutre:
 * 0
 *  0_0
 *    0_0_0
 *    0_0_1
 *    0_0_2
 *  0_1
 *    0_1_0
 *    0_1_1
 *  0_2
 *    0_2_0
 * 1
 *   1_0
 *     1_0_0
 */

export const toId = (parentId: string | undefined, id: string | number) =>
  parentId ? `${parentId}_${id}` : id.toString();

export const isParentToId = (parentId: string, id: string) => {
  if (parentId === id) return false;

  const regex = new RegExp('^' + parentId + '_');

  return regex.test(id);
};

export const isActive = (activeId: string, id: string) =>
  activeId === id || isParentToId(id, activeId);

export const getParentId = (id: string): string | undefined =>
  /_/.test(id) ? id.replace(/_.$/, '') : undefined;

export const createParentIds = (id: string): string[] => {
  const parts = id.split('_').slice(0, -1);
  return parts.reduce<string[]>(
    (list, partId) => [...list, toId(list[list.length - 1], partId)],
    [],
  );
};
