import React, {
  type ComponentPropsWithoutRef,
  type ComponentType,
  forwardRef,
  useCallback,
  useContext,
} from 'react';
import { type TrackEvent } from './Tracker';
import { TrackingContext } from './TrackingProvider';
import { type EventAction, type GA3Event, type TrackingData } from './types';
import { useTracker } from './useTracker';

export type WithTrackerProps<C extends ComponentType> =
  React.ComponentPropsWithoutRef<C> & TrackingProps;

export interface TrackingProps {
  /**
   * Optional action to send with analytics data. Default specified in withTracker.
   */
  trackEventAction?: EventAction;

  /**
   * Label to send with analytics data.
   * Analytics events will not be sent unless a label is provided.
   */
  trackEventLabel?: string;

  /**
   * Custom tracking data to pass through to Tracker.
   */
  trackData?: TrackingData;

  /**
   * Custom tracking event to pass through to Tracker.
   */
  trackEvent?: TrackEvent;

  /**
   * Events to send for GA3 will be depcreated later on.
   */
  trackGA3?: GA3Event;
}

/**
 * Lets a component take some additional props for tracking.
 *
 * If using React tracking, will send an analytics interaction when the event is triggered.
 * If using DOM tracking, passes through the props as data attributes to the element.
 */
export function withTracker<C extends ComponentType<any>, RefType>(
  Component: C,
  {
    event: domEvent = 'onClick',
    defaultAction,
    ga3,
  }: {
    event?: string;
    defaultAction: EventAction;
    ga3?: GA3Event;
  }
) {
  const ComponentWithTracking = forwardRef<
    RefType extends never ? C : RefType,
    ComponentPropsWithoutRef<C> & TrackingProps
  >(
    (
      {
        trackEventAction = defaultAction,
        trackEventLabel,
        trackData,
        trackEvent,
        trackGA3,
        ...props
      },
      ref
    ) => {
      const { isReactTrackingEnabledForTree, ga3Data } =
        useContext(TrackingContext);

      const tracker = useTracker(
        {
          eventAction: trackEventAction,
          ...trackData,
        },
        {
          ga3: {
            ...ga3Data,
            ...ga3,
            ...trackGA3,
          },
        }
      );
      const originalEventHandler = (props as any)[domEvent];

      const handleEvent = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
          if (typeof originalEventHandler === 'function') {
            originalEventHandler(event);
          }
          if (!event.defaultPrevented) {
            tracker.sendEvent(trackEvent, {
              eventLabel: trackEventLabel,
            });
          }
        },
        [originalEventHandler, trackEventLabel, tracker, trackEvent]
      );

      return (
        <Component
          ref={ref}
          {...(props as any)}
          {...(trackEventLabel &&
            (isReactTrackingEnabledForTree
              ? {
                  [domEvent]: handleEvent,
                }
              : {
                  [`data-track-${domEvent.toLowerCase()}`]: JSON.stringify({
                    eventAction: trackEventAction,
                    eventLabel: trackEventLabel,
                    event: trackEvent,
                    ...trackData,
                  }),
                }))}
        />
      );
    }
  );

  ComponentWithTracking.displayName = `withTracker(${
    Component.displayName || Component.name || 'Component'
  })`;

  return ComponentWithTracking;
}
