import { HeaderProps } from '@collab/organisms';
import { defaultLinks } from '@collab/organisms/Footer/Footer';
import { environment } from '@collab/utils/environment';

import { oipSupportEmail } from 'config/config';
import { surveyUrl } from 'features/survey/urls';
import portalEnv from 'utils/environment';

const designPortalUrl = environment.DESIGN_PORTAL_URL;

export const footer = {
  links: {
    contact: { text: 'Contact', href: `mailto:${oipSupportEmail}` },
    ...(portalEnv.SHOW_SURVEY && {
      survey: { text: 'Take the survey', href: surveyUrl, target: '_blank' },
    }),
    ...defaultLinks,
    tCLink: {
      text: 'Terms & Conditions of the Developer Portal',
      href: '/terms-and-conditions',
      target: '_blank',
    },
  },
};

export const header: HeaderProps = {
  withInfoBanner: false,
  navItems: [
    { text: 'Design', href: designPortalUrl },
    { text: 'Develop', href: '/', active: true },
  ],
};
