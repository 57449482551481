import { User as UserModel } from '@collab/models/userModel';
import { UserProfile } from '@collab/organisms/NavMenu';

export const toUserProfile = (user: UserModel): UserProfile => ({
  firstName: user.firstName,
  lastName: user.lastName,
  imageUrl: buildImageUrl(user),
});

const buildImageUrl = (user: UserModel): string | null => {
  if (user.picture === null) return null;

  if (user.idProvider === 'GitHub') {
    return `${user.picture}&size=40`;
  }

  return user.picture;
};
