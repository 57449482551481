import { getLanguage, getTimeZone, parseDate } from '@collab/utils/date';

export const formatDate = (date: string): string => {
  return parseDate(date).toLocaleDateString(getLanguage(), {
    timeZone: getTimeZone(),
  });
};

export const formatDateTime = (date: string): string => {
  return parseDate(date).toLocaleString(getLanguage(), {
    timeZone: getTimeZone(),
  });
};

export const formatTime = (date: string): string => {
  return parseDate(date).toLocaleTimeString(getLanguage(), {
    timeZone: getTimeZone(),
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
