import { AccessToken, VolvoIdToken } from 'libs/volvoId/models';

export const SESSION_KEY = 'volvo_id_access_token';

export const addVolvoIdAccessToken = (apiName: string, token: VolvoIdToken) =>
  sessionStorage.setItem(SESSION_KEY, JSON.stringify({ apiName, token }));

export const removeVolvoIdAccessToken = () =>
  sessionStorage.removeItem(SESSION_KEY);

export const getVolvoIdAccessToken = (): AccessToken | null => {
  const token = sessionStorage.getItem(SESSION_KEY);

  return token ? JSON.parse(token) : null;
};
