import { Icon } from '@volvo-cars/react-icons';
import FocusTrap from 'focus-trap-react';
import {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

import { zIndex } from '@collab/style/customThemeTokens';
import lockBodyScroll from '@collab/utils/lockBodyScroll';

const PopupContainer: React.FC<{
  title: string;
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
}> = ({ title, open, onClose, children }) => {
  const nodeRef = useRef(null);

  return (
    <Transition
      nodeRef={nodeRef}
      timeout={300}
      in={open}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <Container
          nodeRef={nodeRef}
          title={title}
          onClose={onClose}
          transitionState={state}
        >
          {children}
        </Container>
      )}
    </Transition>
  );
};

const Container: React.FC<{
  title: string;
  transitionState: TransitionStatus;
  nodeRef: MutableRefObject<null>;
  onClose?: () => void;
  children: ReactNode;
}> = ({ title, transitionState, nodeRef, onClose, children }) => {
  useEffect(() => {
    lockBodyScroll(true);
    return () => lockBodyScroll(false);
  }, []);

  return (
    <FocusTrap
      focusTrapOptions={{
        fallbackFocus: '#popup',
        initialFocus: false,
        allowOutsideClick: true,
      }}
    >
      <div
        ref={nodeRef}
        className="backdrop"
        style={{
          zIndex: zIndex.popupBackdrop,
          opacity: transitionState.startsWith('enter') ? 1 : 0,
          transition: 'opacity ease-in-out 300ms',
        }}
      >
        <div
          aria-modal="true"
          className="dialog bg-primary"
          role="dialog"
          id="popup"
        >
          {onClose && (
            <button type="button" style={closeButtonStyle} onClick={onClose}>
              <Icon icon="x" size={16} color="currentColor" />
            </button>
          )}
          <p className="font-24 mb-24 font-medium">{title}</p>
          <div className="flex-col">{children}</div>
        </div>
      </div>
    </FocusTrap>
  );
};

const closeButtonStyle: CSSProperties = {
  position: 'absolute',
  top: 9,
  width: 24,
  height: 24,
  right: 9,
};

export default PopupContainer;
