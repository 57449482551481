/**
 * The Model to use when defining a menu.
 */

import {
  CategoryItem,
  MenuStateItem,
  ModuleItem,
  PageItem,
  PageTopicItem,
  SectionItem,
} from './types';
import { labelsToTopics } from './utils/labelsToTopics';
import { toId } from './utils/menuIds';

interface MenuItemModelBase {
  variant: string;
  label: string;
  persistentId?: string;
}

export type AccountItemModel = Omit<MenuItemModelBase, 'label'> & {
  variant: 'account';
  items: PageTopicItemModel[];
};

export type PageItemModel = MenuItemModelBase & {
  variant: 'page';
  href: string;
  items: PageTopicItemModel[];
  isProtected?: boolean;
};

export type SectionItemModel = MenuItemModelBase & {
  variant: 'section';
  items: SectionSubItemModel[];
  isProtected?: boolean;
};

export type ModuleItemModel = MenuItemModelBase & {
  variant: 'module';
  items: ModuleSubItemModel[];
  isProtected?: boolean;
};

export type CategoryItemModel = MenuItemModelBase & {
  variant: 'category';
};

export type PageTopicItemModel = MenuItemModelBase & {
  variant: 'pageTopic';
  href: string;
};

export type ModuleSubItemModel = PageItemModel | SectionItemModel;

export type SectionSubItemModel =
  | PageItemModel
  | ModuleItemModel
  | CategoryItemModel;

export type MenuItemModel =
  | AccountItemModel
  | PageItemModel
  | SectionItemModel
  | CategoryItemModel
  | ModuleItemModel;

export const normalizeModels = (
  items: MenuItemModel[],
  parentId?: string,
): MenuStateItem[] =>
  items.map((item, index) => normalizeModel(item, toId(parentId, index)));

const normalizeModel = (model: MenuItemModel, id: string): MenuStateItem => {
  if (model.variant === 'page') return normalizePage(id, model);
  if (model.variant === 'section') return normalizeSection(id, model);
  if (model.variant === 'module') return normalizeModule(id, model);
  if (model.variant === 'account') return normalizeAccountItem(model);
  return normalizeCategory(id, model);
};

const normalizePage = (
  id: string,
  { label, href, items, isProtected, persistentId }: PageItemModel,
): PageItem => ({
  id,
  variant: 'page',
  label,
  href: normalizeHref(href),
  items: normalizePageTopics(items, id),
  isProtected: !!isProtected,
  persistentId,
});

const normalizeSection = (
  id: string,
  { label, items, isProtected, persistentId }: SectionItemModel,
): SectionItem => ({
  id,
  variant: 'section',
  label,
  items: normalizeSectionSubItems(items, id),
  isProtected: !!isProtected,
  persistentId,
});

const normalizeModule = (
  id: string,
  { label, items, isProtected, persistentId }: ModuleItemModel,
): ModuleItem => ({
  id,
  variant: 'module',
  label,
  items: normalizeModuleSubItems(items, id),
  isProtected: !!isProtected,
  persistentId,
});

const normalizeCategory = (
  id: string,
  { label, persistentId }: CategoryItemModel,
): CategoryItem => ({
  id,
  variant: 'category',
  label,
  persistentId,
});

const normalizePageTopic = (
  id: string,
  { href, label, persistentId }: PageTopicItemModel,
): PageTopicItem => ({
  id,
  variant: 'pageTopic',
  label,
  href: normalizeHref(href),
  persistentId,
});

export const normalizeSectionSubItems = (
  items: SectionSubItemModel[],
  parentId: string,
  offset = 0,
) =>
  items.map((item, index) => {
    const itemId = toId(parentId, offset + index);

    if (item.variant === 'page') {
      return normalizePage(itemId, item);
    }

    if (item.variant === 'module') {
      return normalizeModule(itemId, item);
    }

    return normalizeCategory(itemId, item);
  });

export const normalizeModuleSubItems = (
  items: ModuleSubItemModel[],
  parentId: string,
) =>
  items.map((item, index) =>
    item.variant === 'page'
      ? normalizePage(toId(parentId, index), item)
      : normalizeSection(toId(parentId, index), item),
  );

const normalizePageTopics = (items: PageTopicItemModel[], parentId: string) =>
  items.map((item, index) => normalizePageTopic(toId(parentId, index), item));

const normalizeHref = (href: string): string =>
  href.endsWith('/') || href.includes('#') ? href : `${href}/`;

export const accountPageLink = '/account';

const accountId = 'account';

const normalizeAccountItem = (model: AccountItemModel): PageItem => ({
  id: accountId,
  variant: 'page',
  label: 'Your account',
  href: normalizeHref(accountPageLink),
  items: normalizePageTopics(model.items, accountId),
  isProtected: false,
});

export const createAccountItem = (pageTopics: string[]): AccountItemModel => ({
  variant: 'account',
  items: labelsToTopics(pageTopics),
});
