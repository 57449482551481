'use client';

import { useLayoutEffect, useEffect } from 'react';

// A useLayoutEffect that avoids errors in SSR
const useUniversalLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// Keep the name useLayoutEffect to work out of the box with eslint-plugin-react-hooks
export { useUniversalLayoutEffect as useLayoutEffect };
