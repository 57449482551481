import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const sectionHeadingClass = 'section_heading';

const MAX_RETRIES = 3;

const useHeadingRefs = (): NodeListOf<Element> | undefined => {
  const [refs, setRefs] = useState<NodeListOf<Element> | undefined>();
  const [retry, setRetry] = useState(0);

  const { asPath } = useRouter();

  useEffect(() => {
    const foundRefs = document.querySelectorAll(`.${sectionHeadingClass}`);
    let timeout: NodeJS.Timeout;

    if (foundRefs.length === 0 && retry < MAX_RETRIES) {
      timeout = setTimeout(() => setRetry((current) => current + 1), 10);
    }

    setRefs(foundRefs);

    return () => {
      setRetry(0);

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [asPath, retry]);

  return refs;
};

export default useHeadingRefs;
