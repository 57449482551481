// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable react/display-name */
import {
  DefaultSeo as NextDefaultSeo,
  DefaultSeoProps as NextDefaultSeoProps,
  NextSeo,
} from 'next-seo';

export type SeoProps = {
  title: string;
  description?: string;
  category?: string[];
  image?: string;
  noIndex?: boolean;
};

export type DefaultSeoProps = {
  defaultTitle: string;
  description: string;
  defaultImageSrc: string;
};

export const getPageSeoComponent =
  (rootUrl: string): React.FC<SeoProps> =>
  ({ title, description, image, category = [], noIndex }) => {
    const PageSeo = (
      <NextSeo
        title={buildSeoTitle(title, category)}
        description={description}
        openGraph={{
          images: buildOGImages(image, rootUrl),
        }}
        noindex={noIndex}
      />
    );

    return PageSeo;
  };

export const getDefaultSeoComponent =
  (rootUrl: string): React.FC<DefaultSeoProps> =>
  (defaultProps) => {
    const nextDefaultSeoProps = buildDefaultSeo({ ...defaultProps, rootUrl });
    const DefaultSeo = <NextDefaultSeo {...nextDefaultSeoProps} />;

    return DefaultSeo;
  };

const buildDefaultSeo = ({
  defaultTitle,
  description,
  defaultImageSrc,
  rootUrl,
}: DefaultSeoProps & { rootUrl: string }): NextDefaultSeoProps => ({
  defaultTitle,
  titleTemplate: `%s | ${defaultTitle}`,
  description,
  openGraph: {
    type: 'website',
    site_name: defaultTitle,
    title: defaultTitle,
    url: rootUrl,
    images: [
      {
        url: rootUrl + defaultImageSrc,
        width: 1200,
        height: 630,
      },
    ],
  },
});

const buildSeoTitle = (title: string, category: string[]) =>
  category.length === 0 ? title : [...category, title].reverse().join(' – ');

const buildOGImages = (image: string | undefined, rootUrl: string) => {
  if (!image) return;

  return [
    {
      url: rootUrl + image,
      width: 1200,
      height: 630,
    },
  ];
};
