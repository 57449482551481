import { useEffect, useState } from 'react';
import { StyleProvider, StyleRenderer, ThemePicker } from 'vcc-ui';

import { environment } from '@collab/utils/environment';

import ThemeProvider, { ThemeContextState } from './ThemeProvider';
import { ThemeVariant } from './customThemeTokens';
import { getStyleRenderer } from './getStyleRenderer';

export const styleRenderer = getStyleRenderer();

export const THEME_VARIANT_SESSION_KEY = 'theme-variant';

type Props = {
  children: React.ReactNode;
  renderer?: StyleRenderer;
  themeVariant?: ThemeVariant;
};

const GlobalStyleProvider: React.FC<Props> = ({
  children,
  renderer = styleRenderer,
  themeVariant = 'light',
}) => {
  const [theme, setTheme] = useState<ThemeVariant>(themeVariant);

  const toggleTheme = () => {
    const newState = theme === 'light' ? 'dark' : 'light';

    localStorage.setItem(THEME_VARIANT_SESSION_KEY, newState);
    setTheme(newState);
  };

  // Forces darkmode on darkmode pages.
  const finalTheme = themeVariant === 'dark' ? 'dark' : theme;

  useEffect(() => {
    if (!environment.IS_PROD) {
      setTheme(getSessionTheme() ?? themeVariant);
    }
  }, [themeVariant]);

  useEffect(() => {
    document.body.dataset.colorMode = finalTheme;
  });

  const state: ThemeContextState = {
    theme,
    toggleTheme,
  };

  return (
    <ThemeProvider value={state}>
      <StyleProvider renderer={renderer}>
        <ThemePicker variant={finalTheme}>
          <div data-color-mode={finalTheme}>{children}</div>
        </ThemePicker>
      </StyleProvider>
    </ThemeProvider>
  );
};

const getSessionTheme = (): ThemeVariant | null => {
  const sessionItem = localStorage.getItem(THEME_VARIANT_SESSION_KEY);

  if (sessionItem === 'light' || sessionItem === 'dark') {
    return sessionItem;
  }

  return null;
};

export default GlobalStyleProvider;
