import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { Paragraph } from '@collab/atoms';
import PopupContainer from '@collab/features/popup/PopupContainer';
import { setCookie } from '@collab/utils/cookie';

import { surveyUrl } from './urls';

const SURVEY_IS_SHOWED_ID = 'survey_is_showed_v1';

const Survey: React.FC = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const surveyBlocked = !!Cookies.get(SURVEY_IS_SHOWED_ID);

    if (surveyBlocked) return;

    let timer: NodeJS.Timeout;
    timer = setTimeout(() => {
      setShow(true);
    }, 20000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const onClose = () => {
    setCookie(SURVEY_IS_SHOWED_ID, 'true', { expires: 365 });
    setShow(false);
  };

  const onAccept = () => {
    onClose();
    window.open(surveyUrl, '_blank');
  };

  return <SurveyModal open={show} onAccept={onAccept} onClose={onClose} />;
};

const SurveyModal: React.FC<{
  open: boolean;
  onAccept: () => void;
  onClose: () => void;
}> = ({ open, onAccept, onClose }) => (
  <PopupContainer title="Your opinion matters" open={open} onClose={onClose}>
    <Paragraph>
      Feedback is a gift. Take a few minutes and complete our user survey to
      help us improve the Developer Portal.
    </Paragraph>
    <div className="button-group justify-center pt-48">
      <button type="button" className="button-filled" onClick={onAccept}>
        Go to survey
      </button>
      <button type="button" className="button-outlined" onClick={onClose}>
        Cancel
      </button>
    </div>
  </PopupContainer>
);

export default Survey;
