import Cookies from 'js-cookie';

import { environment } from './environment';

export const setCookie = (
  name: string,
  value: string | object,
  options?: Cookies.CookieAttributes,
) => {
  Cookies.set(name, value, {
    ...options,
    secure: environment.NAME !== 'local',
  });
};
