const possible =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generatePkceParams = async () => {
  const verifier = generateRandomString(64);
  const challenge = await generateCodeChallenge(verifier);
  const noice = generateRandomString(16);

  return { verifier, challenge, noice };
};

export const encode64 = (s: string) => window.btoa(s);

export const decode64 = (s: string) => window.atob(s);

const generateRandomString = (length: number) => {
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const generateCodeChallenge = async (codeVerifier: string) => {
  const digest = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(codeVerifier),
  );

  return encode64(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};
