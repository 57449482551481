import { useRouter } from 'next/router';

const usePagePath = (): string => {
  const { asPath } = useRouter();

  if (asPath === '/') return 'home';
  return asPath;
};

export default usePagePath;
