import {
  type AccountCreatedData,
  type AddPaymentInfoData,
  type AddToCartData,
  type BeginCheckoutData,
  type CartViewData,
  type ChatInteractionData,
  type ConfigFinishData,
  type ConfigStartData,
  type CustomEventData,
  type EventStrict,
  type FormLoadData,
  type FormSubmitData,
  type GA3Event,
  type LoginData,
  type Mode,
  type PageViewData,
  type PurchaseData,
  type SiteNavInteractionData,
  type TrackingData,
  type ViewItemData,
  type WebVitalData,
} from './types';

declare global {
  interface Window {
    dataLayer: unknown[];
    VolvoCarsAnalytics: {
      getDimension: (node: string) => unknown;
    };
  }
}
if (typeof window !== 'undefined' && !('VolvoCarsAnalytics' in window)) {
  Object.defineProperty(window, 'VolvoCarsAnalytics', {
    value: {
      getDimension: (node: string) => {
        const foundNode = window.dataLayer?.findLast?.((event) => event);
        return typeof foundNode === 'object' &&
          !Array.isArray(foundNode) &&
          foundNode !== null
          ? foundNode[node as keyof typeof foundNode]
          : undefined;
      },
    },
    enumerable: true,
    writable: false,
    configurable: false,
  });
}

export interface TrackerOptions {
  /**
   * Forces analytics data from the tracker instance to be lowercase.
   *
   * @default true
   */
  forceLowerCase?: boolean;

  /**
   * Enable logging of sent analytics data in development.
   *
   * @default false
   */
  logging?: boolean;

  /**
   * Defer nonInteraction events until a `pageType` or `pageName` event is
   * present in the dataLayer.
   *
   * @default true
   */
  deferNonInteraction?: boolean;

  disabled?: boolean;

  mode?: Mode;

  ga3?: GA3Event;
}

type TrackEventLegacy = 'interaction' | 'virtualPageView' | 'noninteraction';

export type TrackEvent =
  | 'chat_interaction'
  | 'web_vitals'
  | 'sitenav_interaction'
  | 'custom_event'
  | 'view_item'
  | 'page_view'
  | 'add_to_cart'
  | 'cart_view'
  | 'begin_checkout'
  | 'add_payment_info'
  | 'purchase'
  | 'config_start'
  | 'config_finish'
  | 'form_load'
  | 'form_submit'
  | 'search'
  | 'login'
  | 'account_created';

/**
 * Exposes methods to push analytics data for different events, with some
 * default data given when creating the tracker.
 */
export class Tracker {
  private readonly defaultEventData?: TrackingData | null;
  private readonly forceLowerCase: boolean;
  private readonly deferNonInteraction: boolean;
  private readonly logging: boolean;
  private readonly mode?: Mode;
  private readonly ga3OverrideData?: GA3Event;
  public disabled?: boolean;

  constructor(
    eventData?: TrackingData | null,
    {
      forceLowerCase = true,
      logging = false,
      disabled = false,
      deferNonInteraction = true,
      mode,
      ga3,
    }: TrackerOptions = {}
  ) {
    this.defaultEventData = eventData;
    this.forceLowerCase = forceLowerCase;
    this.deferNonInteraction = deferNonInteraction;
    this.logging = logging;
    this.disabled = disabled;
    this.mode = mode;
    this.ga3OverrideData = ga3;

    // binding these to the instance to ensure that
    // `new Tracker({ eventCategory: 'Foo' }).interaction !== new Tracker({ eventCategory: 'Bar' }).interaction`
    this.interaction = this.interaction.bind(this);
    this.nonInteraction = this.nonInteraction.bind(this);
    this.pushCustomDimension = this.pushCustomDimension.bind(this);
    this.virtualPageView = this.virtualPageView.bind(this);
  }

  private legacySendEvent(event: TrackEventLegacy, eventData?: TrackingData) {
    let analytics: Record<string, any> = {
      ...this.defaultEventData,
      ...eventData,
      ...this.ga3OverrideData,
      event,
    };
    this.push(analytics);
  }

  private sendGA4Event(
    event?: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData
  ) {
    let analytics: TrackingData = {
      ...(event === 'custom_event' && {
        eventCategory: 'not set',
        eventAction: 'not set',
        eventLabel: 'not set',
      }),
      ...this.defaultEventData,
      ...eventData,
      event: this.getEvent(event),
    };
    this.push(analytics, { snakeCaseKeys: true });
  }

  private push(
    eventData?: TrackingData,
    { snakeCaseKeys = false, lowerCaseValues = this.forceLowerCase } = {}
  ) {
    if (typeof window === 'undefined') {
      return;
    }

    if (!eventData || this.disabled) {
      return;
    }

    if (!('dataLayer' in window)) {
      // @ts-ignore
      window.dataLayer = [];
    }

    const transformedEventData: TrackingData = {};
    for (let [key, value] of Object.entries(eventData)) {
      if (value === null || value === '') {
        continue;
      }
      if (typeof value === 'string' && lowerCaseValues) {
        value = value.toLowerCase();
      }
      if (snakeCaseKeys) {
        transformedEventData[camelToSnakeCase(key)] = keysToSnakeCase(value);
      } else {
        transformedEventData[key] = value;
      }
    }

    if (process.env.NODE_ENV === 'development' && this.logging) {
      console.debug('[Analytics]', transformedEventData);
    }

    window.dataLayer.push(transformedEventData);
  }

  // Wait for up to `retries` seconds for a pageType or pageName event in the
  // dataLayer before pushing the event
  private deferedNonInteraction(
    event: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData,
    retries = 60
  ) {
    const hasPageTypeEvent =
      Array.isArray(window.dataLayer) &&
      window.dataLayer.some(isPageTypeOrPageNameEvent);
    if (hasPageTypeEvent || retries <= 0) {
      if (this.isGa3()) {
        this.legacySendEvent('noninteraction', eventData);
      }
      if (this.isGa4()) {
        this.sendGA4Event(event, eventData);
      }
    } else {
      window.setTimeout(() => {
        this.deferedNonInteraction(event, eventData, --retries);
      }, 1000);
    }
  }

  private isGa3() {
    return !this.mode || this.mode === 'ga3' || this.mode === 'both';
  }

  private isGa4() {
    return this.mode === 'ga4' || this.mode === 'both';
  }

  /**
   * @deprecated Use customEvent instead.
   */
  nonInteraction(eventData?: TrackingData) {
    if (this.deferNonInteraction) {
      this.deferedNonInteraction(
        this.isGa4()
          ? this.defaultEventData?.event || 'custom_event'
          : 'noninteraction',
        eventData
      );
    } else {
      if (this.isGa3()) {
        this.legacySendEvent('noninteraction', eventData);
      }
      if (this.isGa4()) {
        this.sendGA4Event(
          this.defaultEventData?.event || 'custom_event',
          eventData
        );
      }
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  interaction(eventData?: TrackingData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', eventData);
    }
    if (this.isGa4()) {
      this.sendGA4Event(
        this.defaultEventData?.event || 'custom_event',
        eventData
      );
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  sendEvent(event?: TrackEvent, eventData?: TrackingData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', eventData);
    }
    if (this.isGa4()) {
      this.sendGA4Event(event, eventData);
    }
  }

  private getEvent(event?: TrackEvent | TrackEventLegacy) {
    if (event) {
      return event;
    }
    if (this.defaultEventData?.event) {
      return this.defaultEventData.event;
    }
    return 'custom_event';
  }

  customEvent(customEventData: CustomEventData & EventStrict) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', customEventData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('custom_event', customEventData);
    }
  }

  siteNavInteraction(siteNavInteractionData: SiteNavInteractionData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', siteNavInteractionData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('sitenav_interaction', siteNavInteractionData);
    }
  }

  chatInteraction(chatInteractionData: ChatInteractionData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', chatInteractionData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('chat_interaction', chatInteractionData);
    }
  }

  pageView(pageViewData: PageViewData) {
    const havePageViewData = Object.keys(pageViewData).length;
    if (
      this.isGa3() &&
      (havePageViewData ||
        (this.ga3OverrideData && Object.keys(this.ga3OverrideData).length))
    ) {
      this.push(
        {
          event: 'pageView',
          ...pageViewData,
          ...(this.ga3OverrideData as TrackingData),
        },
        // GA3 page data wasn't previously lowercased and we don't want to change it
        // and risk breaking existing reports
        { lowerCaseValues: false }
      );
    }
    if (this.isGa4() && havePageViewData) {
      this.sendGA4Event('page_view', {
        ...pageViewData,
        userAgent: window.navigator.userAgent,
      });
    }
  }

  viewItem(viewItemData: ViewItemData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', viewItemData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('view_item', viewItemData);
    }
  }

  addToCart(addtoCartData: AddToCartData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', addtoCartData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('add_to_cart', addtoCartData);
    }
  }

  cartView(cartViewData: CartViewData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', cartViewData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('cart_view', cartViewData);
    }
  }

  beginCheckout(beginCheckoutData: BeginCheckoutData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', beginCheckoutData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('begin_checkout', beginCheckoutData);
    }
  }

  addPaymentInfo(addPaymentInfoData: AddPaymentInfoData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', addPaymentInfoData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('add_payment_info', addPaymentInfoData);
    }
  }

  purchase(purchaseData: PurchaseData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', purchaseData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('purchase', purchaseData);
    }
  }

  configStart(configStartData: ConfigStartData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', configStartData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('config_start', {
        ...configStartData,
        configurationStart: 1,
      });
    }
  }

  configFinish(configFinishData: ConfigFinishData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', configFinishData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('config_finish', {
        ...configFinishData,
        configurationFinish: 1,
      });
    }
  }

  formLoad(formLoadData: FormLoadData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', formLoadData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('form_load', { ...formLoadData, formLoads: 1 });
    }
  }

  formSubmit(formSubmitData: FormSubmitData) {
    if (this.isGa3()) {
      this.legacySendEvent('interaction', formSubmitData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('form_submit', {
        ...formSubmitData,
        formSubmission: 1,
      });
    }
  }

  webVitals(webVitals: WebVitalData) {
    const webVitalsWithUserAgent = {
      ...webVitals,
      userAgent: window.navigator.userAgent,
    };
    if (this.deferNonInteraction) {
      this.deferedNonInteraction(
        this.isGa4() ? 'web_vitals' : 'noninteraction',
        webVitalsWithUserAgent
      );
    } else {
      if (this.isGa3()) {
        this.legacySendEvent('noninteraction', webVitalsWithUserAgent);
      }
      if (this.isGa4()) {
        this.sendGA4Event('web_vitals', webVitalsWithUserAgent);
      }
    }
  }

  virtualPageView(eventData?: TrackingData) {
    if (this.isGa3()) {
      this.legacySendEvent('virtualPageView', eventData);
    }
    if (this.isGa4()) {
      this.sendGA4Event('page_view', eventData);
    }
  }

  pushCustomDimension(
    name: string,
    value?: string | boolean | number,
    valueGa3?: string | boolean | number
  ) {
    if (this.isGa3()) {
      this.push(
        { [name]: typeof valueGa3 !== 'undefined' ? valueGa3 : value },
        { lowerCaseValues: false }
      );
    }
    if (this.isGa4()) {
      this.push(
        { [name]: value },
        { snakeCaseKeys: true, lowerCaseValues: false }
      );
    }
  }

  search(searchTerm: string) {
    this.sendGA4Event('search', {
      searchTerm: searchTerm,
    });
  }

  login(eventData?: LoginData) {
    if (this.isGa4()) {
      this.sendGA4Event('login', eventData);
    }
  }

  accountCreated(eventData?: AccountCreatedData) {
    if (this.isGa4()) {
      this.sendGA4Event('account_created', eventData);
    }
  }
}

function isPageTypeOrPageNameEvent(entry: unknown) {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    ('pageType' in entry ||
      'pageName' in entry ||
      'page_type' in entry ||
      'page_name' in entry)
  );
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function keysToSnakeCase(
  trackingValue?: TrackingData | TrackingData[string]
): TrackingData | TrackingData[string] {
  if (typeof trackingValue !== 'object' || trackingValue === null) {
    return trackingValue;
  }
  let result: TrackingData = {};
  if (Array.isArray(trackingValue)) {
    return trackingValue.map((values) => keysToSnakeCase(values));
  }
  for (const [key, value] of Object.entries(trackingValue)) {
    result[camelToSnakeCase(key)] = keysToSnakeCase(value);
  }
  return result;
}
