import {
  DefaultSeoProps,
  getDefaultSeoComponent,
  getPageSeoComponent,
  SeoProps,
} from '@collab/libs/seo';

const seoUrl = 'https://developer.volvocars.com';

const defaultProps: DefaultSeoProps = {
  defaultTitle: 'Volvo Cars Developer Portal',
  description:
    'From concept to reality. Bring Volvo Cars digital capabilities into your product',
  defaultImageSrc: '/images/developer_hero.jpg',
};

const PageSeo = getPageSeoComponent(seoUrl);
const DefaultSeoBase = getDefaultSeoComponent(seoUrl);

export const DefaultSeo = () => <DefaultSeoBase {...defaultProps} />;

export type { SeoProps };

export default PageSeo;
