import { MenuContent } from '@collab/organisms/NavMenu';
import { environment } from '@collab/utils/environment';

export const menuContent: MenuContent = {
  heading: 'contents',
  footer: {
    heading: 'design',
    link: {
      label: 'Visit design site',
      href: environment.DESIGN_PORTAL_URL,
    },
  },
};
