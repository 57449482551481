import { ExtendCSS, styleRenderer } from 'vcc-ui';

import baseStyles from './baseStyle';

export function getStyleRenderer(extend?: ExtendCSS) {
  const renderer = styleRenderer();
  const allStyles = { ...baseStyles, ...extend };

  Object.keys(allStyles).forEach((selector) =>
    renderer.renderStatic(
      (allStyles as { [index: string]: object })[selector],
      selector,
    ),
  );

  return renderer;
}
