import useSWR from 'swr';

import { useUserContext } from '@collab/features/user';
import { normalizeUser } from '@collab/models/userModel';

import { parseData } from 'libs/api';
import authFetch from 'libs/auth-fetch';

import { usersKey } from './users.cache';

const fetcher = (url: string) =>
  authFetch(url)
    .then((r) => parseData(r, 200))
    .then((data) => normalizeUser(data.user));

const useUser = () => {
  const { data, error, isLoading } = useSWR(usersKey, fetcher);

  return {
    user: data,
    isLoading,
    error,
  };
};

export const useSignedInUser = () => {
  const { isLoggedIn } = useUserContext();
  const { data, error, isLoading } = useSWR(
    isLoggedIn ? usersKey : null,
    fetcher,
  );

  return {
    user: data,
    isLoading: isLoading,
    error,
  };
};

export default useUser;
