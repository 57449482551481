import { type EventAction } from './types';

const clickTagNameMap: Record<string, 'link' | 'button' | 'image' | undefined> =
  {
    A: 'link',
    BUTTON: 'button',
    IMG: 'image',
  };

export function getDefaultEventAction(
  eventType: string,
  element: HTMLElement
): EventAction | undefined {
  if (eventType === 'click') {
    const eventElement = clickTagNameMap[element.tagName];
    if (eventElement) {
      return `${eventElement}|click`;
    }
  } else if (eventType === 'change') {
    if (
      element.tagName === 'INPUT' &&
      (element as HTMLInputElement).type === 'checkbox'
    ) {
      return `check box|${
        (element as HTMLInputElement).checked ? 'select' : 'deselect'
      }`;
    }
  }
}
