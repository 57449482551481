import {
  parseData,
  UnhandledStatus,
  validateResponse,
} from '@collab/utils/api';
import { backend } from '@collab/utils/environment';

const backendApiBaseUrl = `${backend.BASE_URL}/api/v1`;

export { backendApiBaseUrl, parseData, UnhandledStatus, validateResponse };
