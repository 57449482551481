import { formatDate } from '@collab/utils/formatters';

export const providers = {
  GITHUB: 'GitHub',
  GOOGLE: 'Google',
  CDSID: 'CDSID',
  VOLVOID: 'VolvoId',
} as const;

export type Provider = (typeof providers)[keyof typeof providers];

export type ProviderInfo = {
  email: string | undefined;
  id: Provider;
};

type UserInfo = {
  email: string | undefined;
  isVolvoAccount: boolean;
  idProvider: Provider;
};

export const normalizeUserInfo = (user: any): UserInfo => ({
  email: user.emailAddress,
  isVolvoAccount: user.isVolvoAccount,
  idProvider: user.idProvider,
});

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isVolvoAccount: boolean;
  idProvider: Provider;
  createdDate: string;
  picture: string | null;
  roles: string[];
};

export const normalizeUser = (user: any): User => ({
  id: user.id,
  firstName: user.givenName,
  lastName: user.familyName,
  email: user.emailAddress,
  isVolvoAccount: user.isVolvoAccount,
  idProvider: user.idProvider,
  createdDate: formatDate(user.createdDate),
  picture: user.picture,
  roles: user.roles,
});
