export const parseData = async (
  response: Response,
  ...validStatus: number[]
) => {
  await validateResponse(response, ...validStatus);

  return response.json();
};

export const validateResponse = async (
  response: Response,
  ...validStatus: number[]
) => {
  if (!validStatus.includes(response.status)) {
    throw await UnhandledStatus.fromResponse(response);
  }
};

export class UnhandledStatus extends Error {
  constructor(status: number, message: string) {
    super(
      `Unhandled response with status: '${status}' and message: '${message}'`,
    );
  }

  static fromResponse = async (response: Response) => {
    const message = await parseMessage(response);

    return new UnhandledStatus(response.status, message);
  };
}

const parseMessage = async (response: Response) => {
  if (response.headers.get('content-type') === 'application/json') {
    const body = await response.json();
    return JSON.stringify(body);
  }

  return response.text();
};

export class UnauthorizedError extends Error {
  name = 'UnauthorizedError';
}
