import NextLink from 'next/link';

/**
 * We only want to use next/link for internal links.
 * External links are better served with a basic <a> tag.
 */
const NextLinkWrapper: React.FC<{
  href: string;
  target: string;
  'data-protected': boolean;
  children: React.ReactNode;
}> = ({ href, target, children, ...rest }) => {
  const isExternal = href.startsWith('http');
  const isProtected = /\/i\//.test(href) && rest['data-protected'];

  if (isExternal || isProtected) {
    const linkTarget = isProtected ? target : target ?? '_blank';

    return (
      <a href={href} {...rest} target={linkTarget}>
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} passHref target={target} {...rest}>
      {children}
    </NextLink>
  );
};

export default NextLinkWrapper;
