/* eslint-disable no-useless-escape */
/* This file is generated by generate-supported-browsers.ts */

export const supportedBrowsers =
  /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(14[_.]0|14[_.](4|5)|14[_.]8|15[_.](0|1|2|3|4|5|6)|16[_.](0|1|2|3))(?:[_.]\d+)?)|(SamsungBrowser\/(18|19)\.0)|(Edge\/10[5-9](?:\.0)?)|((Chromium|Chrome)\/10[5-9]\.0(?:\.\d+)?)|(Version\/(14\.(0|1)|15\.(0|1|2|3|4|5|6)|16\.(0|1|2|3))(?:\.\d+)? Safari\/)|(Firefox\/10[5-9]\.0\.\d+)|(Firefox\/10[5-9]\.0(pre|[ab]\d+[a-z]*)?)/;

export const browsersInfo = [
  {
    family: 'ios',
    regExp:
      /(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(\d+)[_\.](\d+)(?:[_\.](\d+))?/,
    min: ['14', '0', '0'],
  },
  {
    family: 'samsung',
    regExp: /(SamsungBrowser)\/(\d+)\.(\d+)/,
    min: ['18', '0', '0'],
  },
  {
    family: 'edge',
    regExp: /(Edge)\/(\d+)(?:\.(\d+))?/,
    min: ['105', '0', '0'],
  },
  {
    family: 'chrome',
    regExp: /(Chromium|Chrome)\/(\d+)\.(\d+)(?:\.(\d+))?/,
    min: ['105', '0', '0'],
  },
  {
    family: 'safari',
    regExp: /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//,
    min: ['14', '0', '0'],
  },
  {
    family: 'firefox',
    regExp: /(Firefox)\/(\d+)\.(\d+)\.(\d+)/,
    min: ['105', '0', '0'],
  },
  {
    family: 'firefox',
    regExp: /(Firefox)\/(\d+)\.(\d+)(pre|[ab]\d+[a-z]*)?/,
    min: ['105', '0', '0'],
  },
];
