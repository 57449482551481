import style from './Paragraph.module.css';

const Paragraph: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <p className={`text-secondary oip-text-max-width ${style.paragraph}`}>
    {children}
  </p>
);

export default Paragraph;
