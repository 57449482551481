import { colors } from '@collab/style/customThemeTokens';

const baselineGrid = 8;

export default {
  html: {
    WebkitOverflowScrolling: 'touch',
    overflowY: 'scroll',
    scrollbarColor: `${colors.scrollbar.darkGrey.thumbColor} ${colors.scrollbar.darkGrey.trackColor}`,
    scrollbarWidth: 'thin',
  },

  body: {
    fontFamily: 'Volvo Novum, Arial, sans-serif',
    WebkitFontSmoothing: 'antialiased',
    WebkitTextSizeAdjust: '100%',
  },

  input: {
    WebkitAppearance: 'none',
  },

  '::-webkit-scrollbar': {
    width: baselineGrid,
    height: baselineGrid,
  },

  '::-webkit-scrollbar-track': {
    background: colors.scrollbar.darkGrey.trackColor,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: colors.scrollbar.darkGrey.thumbColor,
  },
};
