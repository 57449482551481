import { ConfigProvider, StyleRenderer } from 'vcc-ui';

import { PopupProvider } from '@collab/features/popup';
import { ToastProvider } from '@collab/features/toasts';
import { UserProvider } from '@collab/features/user';
import PageLayout, { PageMods } from '@collab/layouts/PageLayout';
import { MenuItemModel } from '@collab/organisms/NavMenu';
import PageProtectedProvider from '@collab/providers/PageProtectedProvider';
import { TrackingProvider } from '@collab/providers/TrackingProvider';
import ErrorBoundary from '@collab/setup/ErrorBoundary';
import commonVccUiConfig from '@collab/setup/commonVccUiConfig';
import GlobalStyleProvider from '@collab/style/GlobalStyleProvider';
import ComposeComponents from '@vcc-www/compose-components';
import { BreakpointsProvider } from '@vcc-www/hooks';

import { footer, header } from 'content/appContent';
import MaintenanceConfig from 'features/maintenance/MaintenanceConfig';
import MenuConfig from 'features/menu/MenuConfig';
import MenuPartnerPatch from 'features/menu/MenuPartnerPatch';
import ErrorConfig from 'libs/errors/ErrorConfig';
import { FeatureFlagsProvider } from 'libs/feature-flags/FeatureFlagsProvider';
import { PrevPathProvider } from 'libs/previous-path/PrevPathProvider';
import useTrackId from 'libs/sentry/useTrackId';
import SWRConfig from 'libs/swr/SWRConfig';

type AppProvidersProps = {
  renderer: StyleRenderer;
  pageMods: PageMods | undefined;
  menuItems: MenuItemModel[];
  children: React.ReactNode;
};

const AppProviders: React.FC<AppProvidersProps> = ({
  menuItems,
  pageMods = {},
  renderer,
  children,
}) => {
  useTrackId();
  const themeVariant = pageMods?.darkTheme ? 'dark' : 'light';

  return (
    <ComposeComponents
      components={[
        [GlobalStyleProvider, { renderer, themeVariant }],
        [ConfigProvider, { config: commonVccUiConfig }],
        [ErrorBoundary],
        [PageProtectedProvider],
        [TrackingProvider],
        [FeatureFlagsProvider],
        [ToastProvider],
        [PopupProvider],
        [SWRConfig],
        [UserProvider],
        [ErrorConfig],
        [MaintenanceConfig],
        [MenuConfig, { menuItems }],
        [MenuPartnerPatch],
        [BreakpointsProvider],
        [PageLayout, { pageMods, layoutProps: { footer, header } }],
        [PrevPathProvider],
      ]}
    >
      {children}
    </ComposeComponents>
  );
};

export default AppProviders;
