import { setUser } from '@sentry/nextjs';
import { useEffect } from 'react';

const CLIENT_ID = 'client-id';

const setupTracking = async () => {
  let clientId = localStorage.getItem(CLIENT_ID);

  if (!clientId) {
    clientId = crypto.randomUUID();
    localStorage.setItem(CLIENT_ID, clientId);
  }

  setUser({ clientId });
};

const useTrackId = () => {
  useEffect(() => {
    setupTracking();
  }, []);
};

export default useTrackId;
