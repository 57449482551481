import { ReactNode } from 'react';
import { SWRConfig } from 'swr';

const CustomSWRConfig: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <SWRConfig
    value={{
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    {children}
  </SWRConfig>
);

export default CustomSWRConfig;
