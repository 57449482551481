import { useEffect } from 'react';

import useErrorHandler from './useErrorHandler';

const useErrorListener = (error: any) => {
  const handleError = useErrorHandler();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);
};

export default useErrorListener;
