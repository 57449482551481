import { Event } from '@sentry/nextjs';

import { testBrowserIsSupportedOrNewer } from '@vcc-www/browsers/testBrowserIsSupportedOrNewer';

const beforeSend = (event: Event) => {
  if (!testBrowserIsSupportedOrNewer(navigator.userAgent)) {
    return null;
  }

  return event;
};

export default beforeSend;
