const createMessage = (event: Event) => {
  // Filter out non dom elements.
  const fullPath = (event.composedPath() as HTMLElement[]).filter(
    (e) => !!e.tagName,
  );

  const firstClickIndex = fullPath.findIndex(isTrackedElement);
  if (firstClickIndex !== -1) {
    const clickedElement = fullPath[firstClickIndex];

    // Remove the clicked element and all elements inside it.
    const path = fullPath.slice(firstClickIndex + 1);

    const pathIds = path.map((e) => createIdentity(e, false));
    pathIds.unshift(createIdentity(clickedElement, true));

    return pathIds.reverse().join(' > ');
  }

  return undefined;
};

const isTrackedElement = (e: HTMLElement) =>
  e.tagName === 'A' ||
  e.tagName === 'BUTTON' ||
  e.tagName === 'INPUT' ||
  e.role === 'button';

const createIdentity = (e: HTMLElement, useTextContent: boolean) => {
  let extraText = '';

  if (e.title) {
    extraText += `[title=${e.title}]`;
  } else if (e.ariaLabel) {
    extraText += `[label=${e.ariaLabel}]`;
  } else if (e.id) {
    extraText += `[id=${e.id}]`;
  }

  if (useTextContent && extraText === '' && e.textContent) {
    extraText += `[text=${e.textContent}]`;
  }

  return `${e.tagName.toLocaleLowerCase()}${extraText}`;
};

export default createMessage;
