import { User } from '@collab/models/userModel';

import { useSignedInUser } from 'libs/users/useUser';

type PartnerMenuItemsStatus = 'hidden' | 'visible' | 'loading';

const usePartnerMenuItemsStatus = (): PartnerMenuItemsStatus => {
  const { user, isLoading } = useSignedInUser();

  if (isLoading) {
    return 'loading';
  }

  if (user) {
    return hasPartnerAccess(user) ? 'visible' : 'hidden';
  }

  return 'hidden';
};

const hasPartnerAccess = (user: User): boolean =>
  user.isVolvoAccount || user.roles.includes('partner');

export default usePartnerMenuItemsStatus;
