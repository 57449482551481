import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import MobileMenuProvider from './mobile/MobileMenuState';
import { MenuItemModel } from './models';
import {
  createInitialState,
  FlatItems,
  InsertSectionItems,
  reducer,
  ReducerState,
  RemoveSectionItems,
  ReplaceModuleItems,
} from './stateReducer';
import { MenuStateItem, PageItem } from './types';
import useHeadingRefs from './utils/useHeadingRefs';

type Link = { label: string; href: string };

export type MenuContent = {
  heading: string;
  footer: {
    heading: string;
    link: Link;
  };
};

export type UserProfile = {
  firstName: string;
  lastName: string;
  imageUrl: string | null;
};

export type User = 'loading' | 'error' | 'logged-out' | UserProfile;

export type AccountProps = {
  logout: () => void;
  login: () => void;
  signUp: () => void;
  user: User;
};

export type ContentGuardProps = {
  guard: (href: string) => void;
  isVerified: boolean;
};

type MenuStateActionsContextProps = {
  toggleSubMenuOpen: (id: string) => void;
  closeNonActiveItems: () => void;
  insertSectionItems: (data: InsertSectionItems) => void;
  removeSectionItems: (data: RemoveSectionItems) => void;
  replaceModuleItems: (data: ReplaceModuleItems) => void;
  forceUpdateActiveItem: () => void;
};

type MenuState = {
  accountItem: PageItem;
  items: MenuStateItem[];
  itemsFlat: FlatItems;
  account?: AccountProps;
  isInitialized: boolean;
  contentGuard?: ContentGuardProps;
};

export const MenuStateActionsContext =
  createContext<MenuStateActionsContextProps>({
    toggleSubMenuOpen: () => {},
    closeNonActiveItems: () => {},
    insertSectionItems: () => {},
    removeSectionItems: () => {},
    replaceModuleItems: () => {},
    forceUpdateActiveItem: () => {},
  });
export const MenuContentContext = createContext<MenuContent>({} as MenuContent);
export const MenuStateContext = createContext<MenuState>({} as MenuState);
export const ActiveItemContext = createContext<string | undefined>(undefined);
export const OpenItemsContext = createContext<string[]>([]);
export const HeadingRefsContext = createContext<
  NodeListOf<Element> | undefined
>(undefined);

export const MenuStateProvider: React.FC<{
  items: MenuItemModel[];
  content: MenuContent;
  account?: AccountProps;
  contentGuard?: ContentGuardProps;
  children: React.ReactNode;
}> = ({ items, content, account, contentGuard, children }) => {
  const { asPath } = useRouter();
  const pathWithoutHash = asPath.split('#')[0];
  const [isInitialized, setInitialized] = useState(false);

  const [menuState, dispatch] = useReducer(
    reducer,
    createInitialState(items, pathWithoutHash),
  );

  useEffect(() => {
    // First time we load the application initial state will be set.
    // We should only update the menu when the path is changed after the initial state change.
    if (isInitialized) {
      dispatch({ type: 'href-change', href: pathWithoutHash });
    }

    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathWithoutHash]);

  const toggleSubMenuOpen = useCallback((id: string) => {
    dispatch({ type: 'item-change', id });
  }, []);

  const closeNonActiveItems = () => {
    dispatch({ type: 'close-non-active' });
  };

  const forceUpdateActiveItem = useCallback(() => {
    const path = window.location.pathname.split('#')[0];

    dispatch({ type: 'href-change', href: path });
  }, []);

  const insertSectionItems = useCallback((data: InsertSectionItems) => {
    dispatch({ type: 'insert-section-items', data });
  }, []);

  const removeSectionItems = useCallback((data: RemoveSectionItems) => {
    dispatch({ type: 'remove-section-items', data });
  }, []);

  const replaceModuleItems = useCallback((data: ReplaceModuleItems) => {
    dispatch({ type: 'replace-module-items', data });
  }, []);

  const menuStateActions = useMemo(
    () => ({
      closeNonActiveItems,
      toggleSubMenuOpen,
      insertSectionItems,
      removeSectionItems,
      replaceModuleItems,
      forceUpdateActiveItem,
    }),
    [
      toggleSubMenuOpen,
      insertSectionItems,
      removeSectionItems,
      replaceModuleItems,
      forceUpdateActiveItem,
    ],
  );

  const menuStateContext: MenuState = {
    accountItem: menuState.accountItem,
    items: menuState.items,
    itemsFlat: menuState.itemsFlat,
    contentGuard,
    account,
    isInitialized,
  };

  return (
    <MenuProviders
      menuStateContext={menuStateContext}
      menuStateActions={menuStateActions}
      content={content}
      menuState={menuState}
    >
      {children}
    </MenuProviders>
  );
};

export const MenuProviders: React.FC<{
  menuStateContext: MenuState;
  menuStateActions: MenuStateActionsContextProps;
  content: MenuContent;
  menuState: ReducerState;
  children: React.ReactNode;
}> = ({ menuStateActions, menuStateContext, content, menuState, children }) => (
  <MenuStateActionsContext.Provider value={menuStateActions}>
    <MenuContentContext.Provider value={content}>
      <MenuStateContext.Provider value={menuStateContext}>
        <ActiveItemContext.Provider value={menuState.activeId}>
          <HeadingRefsProvider>
            <OpenItemsContext.Provider value={menuState.openIds}>
              <MobileMenuProvider>{children}</MobileMenuProvider>
            </OpenItemsContext.Provider>
          </HeadingRefsProvider>
        </ActiveItemContext.Provider>
      </MenuStateContext.Provider>
    </MenuContentContext.Provider>
  </MenuStateActionsContext.Provider>
);

const HeadingRefsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const refs = useHeadingRefs();

  return (
    <HeadingRefsContext.Provider value={refs}>
      {children}
    </HeadingRefsContext.Provider>
  );
};
