import useSWR from 'swr';

import { backendApiBaseUrl, parseData } from 'libs/api';

const fetcher = (url: string) =>
  fetch(url)
    .then((r) => parseData(r, 200))
    .then(normalizeMaintenanceMessage);

const useMaintenance = () => {
  const { data: message } = useSWR('/api/validate-be', fetcher);

  const { error, data, isLoading } = useSWR(
    message === 'OK' ? `${backendApiBaseUrl}/backend-status` : null,
    fetcher,
  );

  return {
    message: data ?? message === 'OK' ? undefined : message,
    error,
    isLoading,
  };
};

const normalizeMaintenanceMessage = (data: any): string | undefined =>
  data.message;

export default useMaintenance;
