import { browsersInfo } from './supportedBrowsers';

type SemVer = string[];

const compare = (a: number = 0, b: number = 0) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const compareSemver = (a: SemVer, b: SemVer) =>
  compare(parseInt(a[0]), parseInt(b[0])) ||
  compare(parseInt(a[1]), parseInt(b[1])) ||
  compare(parseInt(a[2]), parseInt(b[2]));

export const testBrowserIsSupportedOrNewer = (userAgentString: string) =>
  browsersInfo.some((info) => {
    const regExp = new RegExp(info.regExp);
    if (!regExp.test(userAgentString)) return false;
    const [, , major, minor, patch] = userAgentString.match(regExp) || [];
    const semVer = [major, minor, patch];
    return compareSemver(semVer, info.min) >= 0;
  });
