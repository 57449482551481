import { useCallback } from 'react';

import { logException } from '@collab/utils/logger';

import { useErrorContext } from './ErrorProvider';

const useErrorHandler = () => {
  const { errorHandler } = useErrorContext();

  return useCallback(
    (error: unknown) => {
      const handled = errorHandler(error);

      if (!handled) {
        logException(error);
      }

      return handled;
    },
    [errorHandler],
  );
};

export default useErrorHandler;
