import { environment } from '@collab/utils/environment';

export const getCurrentTime = () => new Date().getTime();

/***
 * Replaces 'Z' with '+00:00' in a date string, to make it
 * cross browser compatible (safari), and returns a Date object.
 * Throws error if date is Invalid
 */
export const parseDate = (dateString: string) => {
  const date = new Date(dateString.replace('Z', '+00:00'));

  if (isInvalid(date)) {
    throw new InvalidDate(dateString);
  }

  return date;
};

const isInvalid = (date: Date) => isNaN(date.getTime());

let config = {
  language: environment.IS_CLIENT ? navigator.language : undefined,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const getLanguage = () => config.language;

export const getTimeZone = () => config.timeZone;

export const setConfig = (language: string, timeZone: string) => {
  config = {
    language,
    timeZone,
  };
};

export class InvalidDate extends Error {
  constructor(dateString: string) {
    super(`Invalid date: ${dateString}`);
  }
}
