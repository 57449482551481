import { MenuItemModel } from '@collab/organisms/NavMenu';

const tagId = 'generatedMenuContent';

const MenuContentScript: React.FC<{
  menuItems: MenuItemModel[];
}> = ({ menuItems }) => (
  <script
    id={tagId}
    type="application/json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify(menuItems),
    }}
  />
);

export const getMenuContentFromHTML = (): MenuItemModel[] => {
  const menuElement = document.getElementById(tagId);

  if (!menuElement?.innerHTML) {
    throw new Error('No menuContent found');
  }

  return JSON.parse(menuElement.innerHTML);
};

export default MenuContentScript;
