export interface Logger {
  exception: (error: unknown) => void;
}

class DefaultLogger implements Logger {
  exception(error: unknown) {
    console.error(error);
  }
}

let logger: Logger = new DefaultLogger();

export const setLogger = (newLogger: Logger) => {
  logger = newLogger;
};

export const logException = (error: unknown) => {
  logger.exception(error);
};
