import React, { createContext, useContext, useEffect, useState } from 'react';

type PageProtectedProps = {
  isProtected: boolean;
  setProtected: (isProtected: boolean) => void;
};

const PageProtectedContext = createContext<PageProtectedProps>(
  {} as PageProtectedProps,
);

const PageProtectedProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isProtected, setProtected] = useState(false);

  return (
    <PageProtectedContext.Provider value={{ isProtected, setProtected }}>
      {children}
    </PageProtectedContext.Provider>
  );
};

export const usePageProtected = () => {
  const { isProtected } = useContext(PageProtectedContext);

  return isProtected;
};

export const useSetPageProtected = (isRouteProtected: boolean = false) => {
  const { setProtected } = useContext(PageProtectedContext);

  useEffect(() => {
    setProtected(isRouteProtected);
  }, [isRouteProtected, setProtected]);
};

export default PageProtectedProvider;
