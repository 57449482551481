import NextLink from 'next/link';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & { href: string };

const Link: React.FC<Props> = ({ href, children, ...props }) => {
  const isExternal = href.startsWith('http');

  if (isExternal) {
    const { rel, target, ...rest } = props;

    return (
      <a
        href={href}
        target={target ?? '_blank'}
        rel={rel ?? 'noreferrer'}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  );
};

export default Link;
