import { decodeJwt, isJwtExpired } from './jwt';
import { UnauthorizedError } from './response';

export type TokenSet = {
  refreshToken: string;
  accessToken: string;
};

export type TokenStore = {
  getAccessToken: () => string | undefined;
  getRefreshToken: () => string | undefined;
  setTokenSet: (tokenSet: TokenSet) => void;
};

export type Config = {
  baseUrl?: string;
  tokenStore: TokenStore;
  refreshTokens: (refreshToken: string) => Promise<TokenSet>;
};

// The token is not valid 10 seconds before it expires.
// This leaves room for any network latency.
const REFRESH_TOKEN_VALIDITY_OFFSET = 10;

const authFetch = (config: Config) => {
  /**
   * Caches multiple refresh calls to prevent several calls to the refresh endpoint.
   */
  let refreshCall: null | Promise<TokenSet> = null;

  const refreshCaller = async (refreshToken: string) => {
    if (!refreshCall) {
      refreshCall = config.refreshTokens(refreshToken);
    }

    return refreshCall;
  };

  return async (
    input: RequestInfo | URL,
    init?: RequestInit | undefined,
  ): Promise<Response> => {
    const newInput = config.baseUrl ? addBaseUrl(config.baseUrl, input) : input;

    const response = await fetch(newInput, withAccessToken(config, init));

    if (response.status === 401) {
      const refreshToken = config.tokenStore.getRefreshToken();

      if (refreshToken) {
        validateRefreshToken(refreshToken);

        const tokenSet = await refreshCaller(refreshToken);

        config.tokenStore.setTokenSet(tokenSet);

        refreshCall = null;
      } else {
        throw new UnauthorizedError();
      }

      return fetch(newInput, withAccessToken(config, init));
    }

    return response;
  };
};

const addBaseUrl = (
  baseUrl: string,
  input: RequestInfo | URL,
): RequestInfo | URL => {
  if (typeof input === 'string') {
    return baseUrl + input;
  }

  if (input instanceof URL) {
    return new URL(baseUrl + input.toString());
  }

  return new Request(baseUrl + input.url, input);
};

const withAccessToken = (
  config: Config,
  init: RequestInit | undefined,
): RequestInit => {
  const accessToken = config.tokenStore.getAccessToken();

  const newHeaders = new Headers(init?.headers);
  if (accessToken) {
    newHeaders.set('Authorization', `Bearer ${accessToken}`);
  }

  return {
    ...init,
    headers: newHeaders,
  };
};

const validateRefreshToken = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt, jwtBase64Decoder);

  if (isJwtExpired(decodedJwt, REFRESH_TOKEN_VALIDITY_OFFSET)) {
    throw new UnauthorizedError('Refresh token expired');
  }
};

const jwtBase64Decoder = (str: string) => window.atob(str);

export default authFetch;
