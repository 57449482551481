import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from 'react';

type PopupContent = {
  title: string;
  components: ReactElement;
};

type PopupContextState = {
  open: boolean;
  content: PopupContent | undefined;
  openPopup: (content: PopupContent) => void;
  closePopup: () => void;
};

const PopupContext = createContext<PopupContextState>({} as any);

export const usePopup = () => useContext(PopupContext);

export const PopupProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [content, setContent] = useState<PopupContent | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const openPopup = (content: PopupContent) => {
    setContent(content);
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const state: PopupContextState = {
    open,
    content,
    closePopup,
    openPopup,
  };

  return (
    <PopupContext.Provider value={state}>{children}</PopupContext.Provider>
  );
};
