import React, { createContext, Dispatch, useContext, useReducer } from 'react';

import { ToastState } from '../toast.types';
import reducer, { ActionTypes } from './reducer';

type ToastDispatchContextProps = Dispatch<ActionTypes>;

const initialState: ToastState = [];

const ToastStateContext = createContext<ToastState>(initialState);

const ToastDispatchContext = createContext<ToastDispatchContextProps>(() => {});

export const ToastProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

export const useToastState = () => useContext(ToastStateContext);

export const useToastDispatch = () => useContext(ToastDispatchContext);
