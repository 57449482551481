const getValidRedirectUrl = (url: string, invalidUrls: string[]) =>
  invalidUrls.includes(removeTrailingSlash(url)) ? '/' : url;

export const removeTrailingSlashes = (urls: string[]) =>
  urls.map((u) => removeTrailingSlash(u));

export const removeTrailingSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, -1) : url;

export default getValidRedirectUrl;
