import { createContext, useContext } from 'react';

export type MaintenanceState = {
  hasMaintenance: boolean;
  message: string | undefined;
};

type MaintenanceProviderProps = MaintenanceState & {
  children: React.ReactNode;
};

const defaultState: MaintenanceState = {
  hasMaintenance: false,
  message: undefined,
};

export const MaintenanceContext = createContext<MaintenanceState>(defaultState);

const MaintenanceProvider: React.FC<MaintenanceProviderProps> = ({
  hasMaintenance,
  message,
  children,
}) => (
  <MaintenanceContext.Provider
    value={{
      hasMaintenance,
      message,
    }}
  >
    {children}
  </MaintenanceContext.Provider>
);

export const useMaintenance = () => useContext(MaintenanceContext);

export default MaintenanceProvider;
