import { useRouter } from 'next/router';

import { useUserContext } from '@collab/features/user';
import { usePageProtected } from '@collab/providers/PageProtectedProvider';
import { logException } from '@collab/utils/logger';

import { oAuthLogout } from 'libs/auth/redirectLogin';
import useClearUserData from 'libs/users/useClearUserData';
import { getNonProtectedUrl } from 'utils/redirectValidation';

const useLogout = () => {
  const router = useRouter();
  const clearUserData = useClearUserData();
  const isProtected = usePageProtected();

  const { setUserStatus } = useUserContext();

  const logout = async (redirectUrl: string = window.location.pathname) => {
    setUserStatus('logging-out');

    try {
      await oAuthLogout();
    } catch (e) {
      logException(e);
    } finally {
      clearUserData();

      const destination = isProtected ? '/' : getNonProtectedUrl(redirectUrl);

      await router.replace(
        destination === '/' ? '/' : destination + window.location.hash,
      );
      setUserStatus('logged-out');
    }
  };

  return logout;
};

export default useLogout;
