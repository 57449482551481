import { LoginParams } from '@collab/libs/auth/authTypes';
import {
  decode64,
  encode64,
  generatePkceParams,
} from '@collab/libs/auth/security';
import { setLoginCookie } from '@collab/utils/session';

export type LoginConfig = {
  redirectUrl: string;
  prompt?: string;
  state: AddedState;
};

export type AddedState = {
  postLoginRedirectUrl: string;
};

export type LoginState = AddedState & {
  noice: string;
};

export type OAuthPrompt = 'login';

export const getLoginParams = async (
  config: LoginConfig,
): Promise<LoginParams> => {
  const { verifier, challenge, noice } = await generatePkceParams();

  setLoginCookie({ verifier, noice });

  const loginState: LoginState = {
    noice,
    ...config.state,
  };

  return {
    redirectUri: `${window.origin}${config.redirectUrl}`,
    state: encodeState(loginState),
    codeChallenge: challenge,
    prompt: config.prompt,
  };
};

const encodeState = (state: LoginState): string =>
  encode64(JSON.stringify(state));

export const decodeState = (state: string): LoginState =>
  JSON.parse(decode64(state));

export class OAuth2Error extends Error {
  redirectUrl?: string;

  constructor(errorCode: string, description?: string, redirectUrl?: string) {
    super(
      `Error from auth server. Code: ${errorCode}. Description: ${description}`,
    );
    this.redirectUrl = redirectUrl;
  }
}
