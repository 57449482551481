import { useCallback } from 'react';

import { Toast, useToasts } from '@collab/features/toasts';

import useErrorHandler from './useErrorHandler';

const useToastErrorHandler = () => {
  const handleError = useErrorHandler();
  const { errorToast } = useToasts();

  return useCallback(
    (error: unknown, toast: Toast) => {
      const handled = handleError(error);

      if (!handled) {
        errorToast(toast);
      }
    },
    [errorToast, handleError],
  );
};

export default useToastErrorHandler;
