import { ToastModel, ToastState } from '../toast.types';

export type ActionTypes =
  | { type: 'remove'; messageId: string }
  | { type: 'add'; toast: ToastModel };

const MAX_TIME_DIFF = 500;

const reducer = (
  prevState: ToastState = [],
  action: ActionTypes,
): ToastState => {
  switch (action.type) {
    case 'remove': {
      return prevState.filter((item) => item.id !== action.messageId);
    }
    case 'add': {
      return addToast(prevState, action.toast);
    }

    // no default
  }
};

const addToast = (prevState: ToastState = [], toast: ToastModel) => {
  const prevToast = prevState[prevState.length - 1] as ToastModel | undefined;

  if (prevToast?.key === toast.key) {
    if (isWithinTimeInterval(prevToast, toast)) {
      return prevState;
    }

    const newState = prevState.slice(0, -1);
    newState.push({
      ...prevToast,
      timestamp: toast.timestamp,
      count: prevToast.count + 1,
    });

    return newState;
  }

  return [...prevState, toast];
};

const isWithinTimeInterval = (prevToast: ToastModel, toast: ToastModel) =>
  toast.timestamp - prevToast.timestamp <= MAX_TIME_DIFF;

export default reducer;
