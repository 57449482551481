import { useEffect } from 'react';

import { ToastMessage } from '@collab/features/toasts/toast.types';

import useToastErrorHandler from './useToastErrorHandler';

const useToastErrorListener = (error: any, toast: ToastMessage) => {
  const toastError = useToastErrorHandler();

  useEffect(() => {
    if (error) {
      toastError(error, toast);
    }
  }, [error, toast, toastError]);
};

export default useToastErrorListener;
