import { useUserContext } from '@collab/features/user';
import { useToastErrorListener } from '@collab/libs/errors';
import { toUserProfile } from '@collab/libs/userProfile';
import { AccountProps, User } from '@collab/organisms/NavMenu';

import { fetchUserErrorToast } from 'features/user/user.errors';
import useLogin from 'libs/users/useLogin';
import useLogout from 'libs/users/useLogout';
import { useSignedInUser } from 'libs/users/useUser';

const useAccount = (): AccountProps => {
  const { user, error, isLoading } = useSignedInUser();
  const { userStatus, isUserStatusLoading } = useUserContext();
  const login = useLogin();
  const logout = useLogout();

  let userData: User;

  useToastErrorListener(error, fetchUserErrorToast);

  const loading =
    isUserStatusLoading || isLoading || userStatus === 'creating-account';

  if (userStatus === 'logged-out') {
    userData = 'logged-out';
  } else if (loading) {
    userData = 'loading';
  } else if (error) {
    userData = 'error';
  } else if (user) {
    userData = toUserProfile(user);
  } else {
    throw new UnexpectedAccountState(userStatus);
  }

  return {
    signUp: () => login(undefined, 'login'),
    login,
    logout,
    user: userData,
  };
};

class UnexpectedAccountState extends Error {
  constructor(userStatus: string) {
    super(`Unexpected state: ${userStatus}`);
  }
}

export default useAccount;
