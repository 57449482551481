import { environment } from '@collab/utils/environment';

import beforeSend from './beforeSend';
import type { BrowserOptions } from '@sentry/nextjs';

const SENTRY_DSN =
  'https://9687bd62fbf54565a491f4257274d10b@sentry-relay-weu.volvocars.biz/4504651584765952';

export const SENTRY_ENABLED = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

export const CONFIG: BrowserOptions = {
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: environment.NAME,
  release: process.env.NEXT_PUBLIC_RELEASE_ID,
  beforeSend,
};
