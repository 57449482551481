import { environment } from '@collab/utils/environment';

const dataDomainId = '23473597-1534-47e8-9ebf-fbc89f9f1e0f';
const dataDomainScript = `${dataDomainId}${environment.IS_PROD ? '' : '-test'}`;
const optanonUrl = `https://cdn.cookielaw.org/consent/${dataDomainScript}/otSDKStub.js`;
export const oneTrust = { dataDomainScript, optanonUrl };

export const gtmId = 'GTM-MRVRMBJ';

export const oipSupportEmail = 'developer.portal@volvocars.com';
