import { authRedirectPath } from '@collab/libs/auth/authPaths';
import { getLoginParams, OAuthPrompt } from '@collab/libs/auth/oauth2';
import {
  clearLoginSession,
  getTokenCookie,
  setLoginRedirectUrl,
} from '@collab/utils/session';

import { getLoginUrl, logout } from './auth.api';

export const redirectLogin = async (
  postLoginRedirectUrl: string,
  prompt?: OAuthPrompt,
) => {
  clearLoginSession();

  setLoginRedirectUrl(postLoginRedirectUrl);

  const params = await getLoginParams({
    redirectUrl: authRedirectPath,
    prompt,
    state: {
      postLoginRedirectUrl,
    },
  });

  const loginUrl = await getLoginUrl(params);

  window.location.href = loginUrl;
};

export const oAuthLogout = async () => {
  const { refreshToken } = getTokenCookie();

  if (refreshToken) {
    await logout(refreshToken);
  }
};
